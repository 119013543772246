import { Component, Input, OnInit } from '@angular/core';
import { Ont, OntsBySerialNum } from '@ip6m/types';
import { OltService } from '../../services/olt/olt.service';
import { OntService } from '../../services/ont/ont.service';

@Component({
  selector: 'ip6m-ont-config',
  templateUrl: './ont-config.component.html',
  styleUrls: ['./ont-config.component.css']
})
export class OntConfigComponent implements OnInit {
  @Input() slotId: number;
  @Input() portId: number;
  @Input() ontt:Ont;
  autoFoundOntList$: Ont[];
  ontList$: OntsBySerialNum;
  isOntsLoaded$ = false;
  constructor(private oltService: OltService, public ontService: OntService) { }

  ngOnInit(): void {
    console.log(this.ontt)
  }


  clickActOnt(id: number) {
    this.ontService.activateOnt(this.oltService.oltid, this.autoFoundOntList$[id]).subscribe(value => {
      if(value.hasOwnProperty('activated') && value['activated']) {
        this.autoFoundOntList$.splice(id, 1); // remove ONT from autofound list
        setTimeout (() => {
          this.ontService.getOnts(this.oltService.oltid, this.slotId, this.portId).subscribe(value => {
            this.ontList$ = value;  // refresh ont list
          })
        }, 2000);
      }      
    });
  }

  
  clickDeactOnt(sn: string) {
    this.ontService.deactivateOnt(this.oltService.oltid, this.ontList$[sn]).subscribe(value => {
      // this.ontList$.splice(id, 1);
      setTimeout (() => {
        this.ontService.getAutoFoundOnts(this.oltService.oltid, this.slotId, this.portId).subscribe(value => {
          this.autoFoundOntList$ = value;
        });
      }, 5000); // It takes some seconds to autofind the deleted ont.
    })
  }

}
