import { Component, Input, OnInit } from '@angular/core';
import { EthState, OntRealTimeData } from '@ip6m/types';
import { ONT_t } from '../../pages/map/map.component';
import { RealTimeDataService } from '../../services/realtime/real-time-data.service';
@Component({
  selector: 'ip6m-ontpopup',
  templateUrl: './ontpopup.component.html',
  styleUrls: ['./ontpopup.component.css'],
})
export class OntpopupComponent  {
  
  


  constructor() {}

  

  
}
