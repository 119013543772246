import { Component, AfterViewInit } from '@angular/core';
import { CustomerDBStatus } from '@ip6m/types';
import { CustomerService } from '../../services/customer/customer.service';
import { ChartService } from '../../services/chart/chart.service';

@Component({
  selector: 'ip6m-ontsummary',
  templateUrl: './ontsummary.component.html',
  styleUrls: ['./ontsummary.component.css'],
})
export class OntSummaryComponent implements AfterViewInit {
  data: CustomerDBStatus;
  haveCustomers: boolean = false;
  ontNum:number;
  constructor(public chartService:ChartService){}

  ngAfterViewInit() {
    
   
   this.chartService.generateOntChart()
  let intId= setInterval(()=>{
    if(this.chartService.ontsLoaded)
    {
      clearInterval(intId)
      this.ontNum=this.chartService.ddms.length
    }
    
  },200)
     
  }




 
}
