import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import { ONT_t } from '../../pages/map/map.component';
import { MatDialog } from '@angular/material/dialog';

import { OLT_t } from '../../pages/map/map.component';
import { OntOpticalDdmInfo, OntState } from '@ip6m/types';
import 'leaflet.markercluster';
import { GeoJsonObject } from 'geojson';
import { MarkerService } from '../marker/marker.service';
import { LayerService } from '../layer/layer.service';
@Injectable()
export class MapService {
  map: any;

  oltsActive:boolean=true;
  oltMarkers = [];
  mouseStopped:boolean=false;
  olts: OLT_t[] = [];
  oltPlaceholderMarkers = [];


  private oltCluster: L.Map | L.LayerGroup<any>;

  shownOnZoom: boolean = false;

  constructor(
    public dialog: MatDialog,
    public layerService: LayerService,
    private markerService:MarkerService
  ) {}

  init(selector) {
    this.oltMarkers = [];
    this.olts = [];
    this.oltPlaceholderMarkers = [];

    this.oltCluster = L.markerClusterGroup({
      showCoverageOnHover: false,
      iconCreateFunction: (cluster) => {
        let children = cluster.getAllChildMarkers();
        let clients = 0;
       
        for (let i = 0; i < children.length; i++) {
          for (let index = 0; index < this.olts.length; index++) {
            if (
              this.olts[index].lon == children[i].getLatLng().lng &&
              this.olts[index].lat == children[i].getLatLng().lat
            ) {
              if(parseInt(this.olts[index].clients))
              clients += parseInt(this.olts[index].clients);
              
             
              break;
            }
          }
        }

        let newStyles = document.createElement('style');
        document.head.append(newStyles);
        newStyles.innerHTML =
          '.' +
          'oltcluster {' +
          'color:black;font-weight:bold;background:' +
          'rgb(69, 184, 46)' +
          ';border-radius:10px;border:2px black solid;}';

        var k = L.divIcon({
          className: 'oltcluster',
          html:
            "<div  style='margin-top:3px;text-align:center;padding:0 0;'>" +
            children.length +
            ' OLTs<br>' +
            clients +
            ' clients</div>',
          iconAnchor: [0, 0],
          iconSize: new L.Point(100, 50),
        });
        return k;
      },
    });

    this.map = L.map(selector, {
      center: [47.31648293428335, 19.440462491963505],
      zoom: 7,
    });
    L.tileLayer(
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      {
       
        maxZoom: 17,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken:
          'pk.eyJ1IjoidGlzemE0NDMiLCJhIjoiY2t0bW9zY2hlMGU0aTJwczg4a2NqcWVwMSJ9.Iyu2kLeosK5RdMDXzwEWaw',
      }
    ).addTo(this.map);
    this.map.on('click',(e)=>{
      var lat  = e.latlng.lat.toFixed(5);
      var lon  = e.latlng.lng.toFixed(5);
      console.log(lat+"   "+lon)
    })

    this.oltCluster.addTo(this.map);

    this.layerService.loadGeoJson(this.map);

    

    return this.map;
  }
  


  toggleOlts()
  {
    this.oltsActive=!this.oltsActive
    
   
    if(!this.oltsActive)
    {
      this.map.removeLayer(this.oltCluster)
    }
    else
    {
      this.map.addLayer(this.oltCluster)
    }
  }
 
  addPlaceholderOlt(olt: OLT_t) {
    let index = this.olts.length - 1;
    let newStyles = document.createElement('style');
    document.head.append(newStyles);
    newStyles.innerHTML =
      '.' +
      'oltmarker' +
      index.toString() +
      ' {' +
      'color:black;font-weight:bold;background:' +
      olt.color +
      ';border-radius:10px;border:2px black solid;}';

    this.oltPlaceholderMarkers.push(
      L.marker([olt.lat, olt.lon], {
        icon: L.divIcon({
          className: 'oltmarker' + index.toString(),
          html:
            "<div  style='margin-top:3px;text-align:center;padding:0 0;'>" +
            olt.olt_name +
            '<br>' +
            olt.clients +
            ' </div>',
          iconAnchor: [0, 0],
          iconSize: new L.Point(100, 50),
        }),
      }).addTo(this.oltCluster)
    );

    this.oltPlaceholderMarkers[
      this.oltPlaceholderMarkers.length - 1
    ].setZIndexOffset(100);
  }
  addOlt(olt: OLT_t) {
    for (let index = 0; index < this.olts.length; index++) {
      if (this.olts[index].olt_name == olt.olt_name) {
        return;
      }
    }
    for (let index = 0; index < this.oltPlaceholderMarkers.length; index++) {
      if (
        olt.lat == this.oltPlaceholderMarkers[index].getLatLng().lat &&
        olt.lon == this.oltPlaceholderMarkers[index].getLatLng().lng
      ) {
        this.map.removeLayer(this.oltPlaceholderMarkers[index]);
        this.oltCluster.removeLayer(this.oltPlaceholderMarkers[index]);
        break;
      }
    }

    this.olts.push(olt);
    let index = this.olts.length - 1;
    let newStyles = document.createElement('style');
    document.head.append(newStyles);
    newStyles.innerHTML =
      '.' +
      'oltmarker' +
      index.toString() +
      ' {' +
      'color:black;font-weight:bold;background:' +
      olt.color +
      ';border-radius:10px;border:2px black solid;}';
    console.log("HEE")
    this.oltMarkers.push(
      L.marker([olt.lat, olt.lon], {
        icon: L.divIcon({
          className: 'oltmarker' + index.toString(),
          html:
            "<div  style='margin-top:3px;text-align:center;padding:0 0;'>" +
            olt.olt_name +
            '<br>' +
            olt.clients +
            ' </div>',
          iconAnchor: [0, 0],
          iconSize: new L.Point(100, 50),
        }),
      }).addTo(this.oltCluster)
    );

    this.oltMarkers[this.oltMarkers.length - 1].setZIndexOffset(100);

    this.oltMarkers[index].on('click', (ev) => {
      for (let j = 0; j < this.markerService.ontMarkers.length; j++) {
        if (this.markerService.onts[j].oltName == this.olts[index].olt_name) {
          if (!this.olts[index].showOnts && this.markerService.onts[j].lon && this.markerService.onts[j].lat) {
            this.markerService.ontMarkers[j].addTo(this.map);
          } else {
            this.map.removeLayer(this.markerService.ontMarkers[j]);
          }
        }
      }
      if (!this.olts[index].showOnts) {
        this.olts[index].showOnts = true;

        if (this.olts[index].color == 'rgb(69, 184, 46)') {
          this.oltMarkers[index].valueOf()._icon.style.backgroundColor =
            'rgb(133, 255, 137)';
        } else if (this.olts[index].color == 'red') {
          this.oltMarkers[index].valueOf()._icon.style.backgroundColor =
            'rgb(255, 133, 133)';
        }
      } else {
        this.olts[index].showOnts = false;

        this.oltMarkers[index].valueOf()._icon.style.backgroundColor =
          this.olts[index].color;
      }
      
    });
  }
  
}
