import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { ConfigurationService } from 'libs/client/feature-configuration/src/lib/services/configuration/configuration.service';
import { IdTokenData } from '../models/id-token-data';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private configService: ConfigurationService) {}

  get isUserAuthEnabled(): boolean {
    return this.configService.publicConfig.userAuthEnabled;
  }

  get isLoggedIn(): boolean {
    if(this.isUserAuthEnabled === false) {
      return true;
    }
    else {
      return !!this.accessToken;
    }
  }

  get isAdmin() {
    let isAdmin = false;
    const token = this.decodedIdToken;
    if(token) {
      token.roles.forEach(role => {
        if(role.id == 3) {
          isAdmin = true;
        }
      });
    } 
    return isAdmin;
  }

  get accessToken(): string | null {
    return localStorage.getItem('accessToken');
  }

  get decodedIdToken(): IdTokenData | null {
    const encodedToken = localStorage.getItem('idToken');
    return encodedToken ? jwtDecode(encodedToken) : null;
  }

  updateTokens(tokens: { accessToken: string; idToken: string }) {
    localStorage.setItem('accessToken', tokens.accessToken);
    localStorage.setItem('idToken', tokens.idToken);
  }

  clearTokens() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
  }
}
