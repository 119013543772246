 <h1 mat-dialog-title>
    Slot: <span primary class="kiemelt">{{ data.slot }}</span> 
    Port: <span class="kiemelt">{{ data.port.id }}</span>,
    <span *ngIf="data.port.hwPortDesc">
        <span *ngIf="data.port.hwPortDesc.data.length">
            {{ data.port.hwPortDesc | buffer }}
        </span>
        <span class="italic" *ngIf="data.port.hwPortDesc.data.length === 0">
            empty description
        </span>
    </span>
 </h1>
 
 <div mat-dialog-content>
    <ip6m-ontlist [slotId]="data.slot" [portId]="data.port.id"></ip6m-ontlist>
 </div>
 <div mat-dialog-actions>
    <button mat-button [mat-dialog-close] cdkFocusInitial>Cancel</button>
 </div>
