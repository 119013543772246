
<h1 style="padding-top: 20px">{{ this.wrongCoordOntsStore.length }} ONT(s) with wrong coordinates</h1>
<div class="table-container">
  <div *ngIf="this.loaded">
    <mat-checkbox style="margin-right:10px" (click)="this.order()" [(ngModel)]="this.wrong"> Wrong </mat-checkbox> 
    <mat-checkbox style="margin-right:10px"  (click)="this.order()" [(ngModel)]="this.notSet"> Not Set </mat-checkbox> 
    <mat-checkbox [(ngModel)]="this.noDist" (click)="this.order()" > No Distance </mat-checkbox> 
</div> 
  <table
    mat-table
    [dataSource]="wrongCoordOntDatasource"
    matSort
    class="mat-elevation-z8"
  >
    <ng-container *ngFor="let col of wrongCoordianteOntsColumns" matColumnDef="{{ col }}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ col }}</th>
      <td mat-cell *matCellDef="let element" >
        {{ element[col] }}
        <button
          *ngIf="col == ' '"
          mat-button
          (click)="this.navigationService.jumpToWeakOnt(element)"
        >
          <mat-icon> map</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="wrongCoordianteOntsColumns"></tr>
    <tr
      class="row"
      mat-row
      *matRowDef="let row; columns: wrongCoordianteOntsColumns"
      (click)="this.navigationService.openOnt(row.ontSn)"
    ></tr>
  </table>
</div>
