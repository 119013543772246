import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ONT_t } from '../../pages/map/map.component';
import { ChartService } from '../../services/chart/chart.service';
import { MarkerService } from '../../services/marker/marker.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { OntService } from '../../services/ont/ont.service';
let search;

interface weakOnt {
  ontSn: string;
  signal: string;
}
@Component({
  selector: 'ip6m-weakontlist',
  templateUrl: './weakontlist.component.html',
  styleUrls: ['./weakontlist.component.css'],
})
export class WeakontlistComponent implements OnInit {
  weakOntColumns: string[] = ['ontSn', 'signal', ' '];

  public weakOnts: weakOnt[] = [];

  weakOntDataSource = new MatTableDataSource<weakOnt>(this.weakOnts);
  constructor(
    private chartService: ChartService,
    public navigationService: NavigationService,
    public markerService: MarkerService
  ) {}
  @ViewChild(MatSort) sort: MatSort;
  ngOnInit(): void {
    let id1 = setInterval(() => {
      if (this.markerService.ontsLoaded) {
        for (let index = 0; index < this.chartService.ddms.length; index++) {
          if (this.chartService.ddms[index].signal <= -25) {
            let str = this.chartService.ddms[index].signal.toString() + 'dBm';
            this.weakOnts.push({
              ontSn: this.chartService.ddms[index].sn,
              signal: str,
            });
          }
        }

        this.weakOntDataSource = new MatTableDataSource<weakOnt>(this.weakOnts);
        this.weakOntDataSource._updateChangeSubscription();
        this.weakOntDataSource.sort=this.sort

        clearInterval(id1);
      }
    }, 200);
  }
}
