import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OntEthernetStatus } from '@ip6m/types';

@Injectable({
  providedIn: 'root'
})
export class EthService {

  constructor(private http:HttpClient) { }


  getEthData(ontsn)
  {
    return this.http.get<OntEthernetStatus>("/api/v1/datacollector/"+ontsn+"/ethstate");
    
  }
}
