<mat-card style="padding:20px">
<h1 *ngIf="this.olts[0]">{{ this.olts.length }} OLT(s)</h1>
<h1 *ngIf="!this.olts[0]">Loading...</h1>
<mat-list dense *ngIf="olts" style="padding:20px">
   
    <mat-list-item class="mat-list-item" [ngStyle]="{'background-color':olt.oltColor}"  *ngFor="let olt of olts">
        
        <span id="name">{{olt.oltName}}</span>
            <span id="status">{{olt.oltStatus}}</span>
    </mat-list-item>
</mat-list>
</mat-card>

