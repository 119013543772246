import { Component, OnInit, ViewChild } from '@angular/core';
import { OntpopupComponent } from '../../components/ontpopup/ontpopup.component';
import { MapService } from '../../services/map/map.service';
import { OntDownCause } from '@ip6m/types';
import { OntState } from '@ip6m/types';
import { Ont } from '@ip6m/types';
import { OltlistService } from '../../services/oltlist/oltlist.service';
import { RealTimeDataService } from '../../services/realtime/real-time-data.service';
import * as L from 'leaflet';
import { DatacollectorService } from '../../services/datacollector/datacollector.service';
import { MarkerService } from '../../services/marker/marker.service';
import { LayerService } from '../../services/layer/layer.service';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { OntService } from '../../services/ont/ont.service';
import { TransformsService } from '../../services/transforms/transforms.service';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SearchService } from '../../services/search/search.service';
export interface ONT_t {
  customer_id: number;
  service_id: string;
  lon: number;
  lat: number;
  city: string;
  street: string;
  houseNum: string;
  name: string;
  ontSn: string;
  signal: number;
  status: OntState;
  lastDownCause: OntDownCause;
  oltName: string;
  oltSlot: number;
  oltPort: number;
  ontType: string;
  ontImage: string;
}

export interface OLT_t {
  lon: number;
  lat: number;
  olt_name: string;
  color: string;
  clients: string;
  showOnts: boolean;
}

@Component({
  selector: 'ip6m-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  entryComponents: [OntpopupComponent],
})
export class MapComponent implements OnInit {
  constructor(
    public mapService: MapService,
    private oltlistservice: OltlistService,
    public ontService: OntService,
    private markerService: MarkerService,
    public layerService: LayerService,
    public searchService:SearchService,
    private http:HttpClient
  ) {}

  ngOnInit() {
   
   /*setTimeout(()=>{
    let element=document.getElementById("map2")
    if(element)
    {
      console.log(element)
      const viewer = new Viewer({
        accessToken: "MLY|6412090998862240|2e6ec0c775f497690f1f15d2c9d63a96",
        container:"map2",
        imageId: "6334268553315270",
      });
      console.log(viewer)
      
    }
  


   this.http.get<any>("https://graph.mapillary.com/images?access_token=MLY|6412090998862240|2e6ec0c775f497690f1f15d2c9d63a96&fields=id&bbox=21.49200,47.75502,22.05368,48.13493&start_captured_at=2021-01-01T14:48:00.000Z&limit=5000").subscribe((values)=>{
  

       Object.values(values).forEach((c) => {
        Object.values(c).forEach((cc) => {

          this.http.get<any>("https://graph.mapillary.com/"+cc["id"]+"?access_token=MLY|6412090998862240|2e6ec0c775f497690f1f15d2c9d63a96").subscribe((img)=>{
           
            //L.marker([img["geometry"]["coordinates"][1],img["geometry"]["coordinates"][0]]).addTo(this.mapService.map)
          })
         
        })
        
       })
   },error=>{
     console.log(error)
   })
  },5000)*/






 
    this.mapService.init('map');
    let id = setInterval(() => {
      if (this.markerService.ontsLoaded) {
        
        console.log(
          'Map loaded with ' + this.markerService.onts.length + ' onts!'
        );
        this.markerService.startUpdate()
        this.initOlts();
        clearInterval(id);
      }
    }, 50);
  }

  private initOlts() {
    let center = L.latLngBounds(
      L.latLng(
        this.oltlistservice.oltList[0].gpsLatitude,
        this.oltlistservice.oltList[0].gpsLongitude
      ),
      L.latLng(
        this.oltlistservice.oltList[0].gpsLatitude,
        this.oltlistservice.oltList[0].gpsLongitude
      )
    );
    for (let index = 0; index < this.oltlistservice.oltList.length; index++) {
      let color = 'rgb(69, 184, 46)';
      if (this.markerService.customerNums[index] == 0) color = 'red';
      let olt: OLT_t;
      if (this.oltlistservice.oltList[index].datacollector) {
        olt = {
          lon: this.oltlistservice.oltList[index].gpsLongitude,
          lat: this.oltlistservice.oltList[index].gpsLatitude,
          olt_name: this.oltlistservice.oltList[index].name,
          color: color,
          clients:
            this.markerService.customerNums[index].toString() + ' clients',
          showOnts: false,
        };

        //Replace placeholder OLT marker with the actual data
      } else {
        olt = {
          lon: this.oltlistservice.oltList[index].gpsLongitude,
          lat: this.oltlistservice.oltList[index].gpsLatitude,
          olt_name: this.oltlistservice.oltList[index].name,
          color: 'grey',
          clients: 'N/A clients',
          showOnts: false,
        };
      }
      center.extend(L.latLng(olt.lat, olt.lon));
      this.mapService.addOlt(olt);
    }
    this.mapService.map.fitBounds(center);
  }
}
