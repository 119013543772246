<h1 mat-dialog-title>
    <p>ID: {{ this.ont.customer_id }} - {{ this.ont.name }}</p>
    <p>{{ this.ont.city }}, {{ this.ont.street }} {{ this.ont.houseNum }}.</p>
  </h1>
  
  <div mat-dialog-content class="content">
    <div class="currData">
      <div class="signals">
      <div class="signal" [ngStyle]="{ 'background-color': this.signalColor }">
        <h3 class="center">
          Signal: <span >{{ this.displayedSignal }}<span *ngIf="this.signal"> dBm</span></span><br>
          CATV : <span >{{ this.displayedCATV }}<span *ngIf="this.catvsignal"> dBm</span></span>
        </h3>
      </div>
      <div class="eth" [ngStyle]="{ 'background-color': this.ethColor }">
        <h3 class="center">
          ETH status: <span >{{ this.eth }}</span><br>
          <span *ngIf="this.ethSpeed">{{ this.ethSpeed }}</span>
        </h3>
      </div>
    </div>
    <div class="text">
      <h3>
        OLT: {{ this.ont.oltName }} {{ this.ont.oltSlot }}/{{ this.ont.oltPort
        }}<br />
        Serial: {{ this.ont.ontSn }}
      </h3>
    </div>
    </div>

    <mat-tab-group *ngIf="!this.ontService.isPopup" (selectedTabChange)="tabChanged($event)" #tabGroup>
      <mat-tab label="RealTime" >
        <div class="graphs" >
          <div  id="realtimeSignalChart"></div>
        </div>
      </mat-tab>
  
      <mat-tab *ngFor="let type of chartTypes" label="{{ type }}">
        <div class="graphs">
          <ip6m-historic-chart
            *ngIf="this.interval == type"
          ></ip6m-historic-chart>
        </div>
      </mat-tab>
    </mat-tab-group>

    <h3  class="center" *ngIf="this.ont && this.ontService.isPopup">{{ this.ont.ontType }}</h3>

   
    <img
      mat-card-image
      *ngIf="this.ont && this.ont.ontImage != '../../../assets/images/.png' && this.ontService.isPopup"
      src="{{ this.ont.ontImage }}"
    />
  </div>
  
  