<div class="serviceports" *ngIf="servicePorts$.length else no_service_ports">
<h4 class="mat-h4">Service Ports </h4>
<table *ngFor="let srvPort of servicePorts$; let i = index;">
    <tr>
        <td>Download: {{ srvPort.download }}</td>
        <td>Upload: {{ srvPort.upload }}</td>
        <td rowspan="3">
            <button mat-mini-fab color="warn" 
                    matTooltip="Delete Service Port"
                    (click)="clickDeleteSrvPort(i)">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </tr>
    <tr>
        <td>Vlan: {{ srvPort.vlanId }}</td>
        <td>{{ srvPort.portType | srvporttype }}: {{ srvPort.portIndex }}</td>       
    </tr>
    <tr>
        <td>User vlan: {{ srvPort.userVlanId | vlanid }}</td>
        <td>Tag transform: {{ srvPort.tagTransform | tagtransform }}</td>
    </tr>
</table>
</div>

<ng-template #no_service_ports>
    <div class=no-service-ports>
        <h4 class=mat-h4>No service ports added to this ONT</h4>
    </div>
</ng-template>
