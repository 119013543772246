<div #map id="map"></div>

<!--<div id="map2"></div>-->
<button  mat-flat-button id="searchCollapse" (click)="this.searchService.toggleForm()">
  <mat-icon>search</mat-icon>
</button>
<button mat-flat-button id="mapMode" (click)="this.layerService.changeMap(this.mapService.map)">
  <img src="assets/images/satelite.png" id="sateliteImage" />
</button>
<button mat-flat-button id="toggleOlt"  [ngStyle]="
    {'border':  + this.layerService.satelite ? '1px solid white':'1px solid black',
      'color':  + this.layerService.satelite ? this.mapService.oltsActive? 'black':'white' :'black',
      'background-color':  + this.mapService.oltsActive ? 'lightgreen':'rgba(128, 128, 128, 0.322);'}"
      (click)="this.mapService.toggleOlts()">
  OLT
</button>


<button mat-flat-button id="toggleWire"  [ngStyle]="
    {'border':  + this.layerService.satelite ? '1px solid white':'1px solid black',
      'color':  + this.layerService.satelite ? this.layerService.wireActive ? 'black':'white' :'black',
      'background-color':  + this.layerService.wireActive  ? 'lightgreen':'rgba(128, 128, 128, 0.322);'}"
      (click)="this.layerService.toggleWire(this.mapService.map)">
  Fiber
</button>

<ip6m-searchform  [ngStyle]="{'transform':  + this.searchService.active ? 'translate(0,0)' : 'translate(0,-500px)'}"  id="searchForm"></ip6m-searchform>
<ip6m-ontpopup  *ngIf="this.ontService.isPopup" id="ontpopup" class="ontpopup"></ip6m-ontpopup>

