import { _DisposeViewRepeaterStrategy } from '@angular/cdk/collections';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OLT } from '@ip6m/types';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OltlistService } from '../../services/oltlist/oltlist.service';

@Component({
  selector: 'ip6m-oltinfo',
  templateUrl: './oltinfo.component.html',
  styleUrls: ['./oltinfo.component.css']
})
export class OltinfoComponent implements OnInit {
  oltOidInfo$: Object;
  oltid$: string;
  oltList$: OLT[] = this.oltlistService.oltList;

  constructor(private http:HttpClient, protected route: ActivatedRoute, private oltlistService: OltlistService) { }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.oltid$ = params.get('id');
    });
    this.oltOidInfo$ = this.http.get("/api/v1/olt/" + this.oltid$ + "/system")
      .pipe(
        catchError(this.handleError)
      );
  }
  private handleError(error: HttpErrorResponse) {
    return throwError('OLT error');
  }
}
