import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurationService } from './services/configuration/configuration.service';

function initConfiguration(configService: ConfigurationService) {
  return () => configService.initConfig();
}
@NgModule({
  imports: [CommonModule],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: initConfiguration,
    deps: [ConfigurationService],
    multi: true
  }]
})
export class ClientFeatureConfigurationModule {}
