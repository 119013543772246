import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Port } from '@ip6m/types';

export interface PortData {
  slot: string,
  port: Port
}

@Component({
  selector: 'ip6m-portdialog',
  templateUrl: './portdialog.component.html',
  styleUrls: ['./portdialog.component.css']
})
export class PortdialogComponent {

  constructor(
    public dialogRef: MatDialogRef<PortdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PortData) {
      console.log(data);
    }

}
