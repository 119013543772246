import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OLT } from '@ip6m/types';
import { MessageService } from '../message/message.service';
@Injectable({
  providedIn: 'root'
})
export class OltlistService {
  oltList: OLT[] = [];

  constructor(private http:HttpClient, private messageService: MessageService) { 
      this.http.get<OLT[]>("/api/v1/olt").subscribe(
        value => {
          this.oltList.push.apply(this.oltList, value);
          this.messageService.add('OltlistService: Olt list fetched');
        },
        (error) => {
          console.error(error.error.message);
          this.messageService.add('OLT db error: ' + error.error.message);
        }
      );
  }
  getOlts()
  {
    return this.http.get<OLT[]>("/api/v1/olt");
  }
  
}
