import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomerData, CustomersBySerial } from '@ip6m/types';
import { CustomerService } from '../../../services/customer/customer.service';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { AddServiceDialogComponent } from '../add-service-dialog/add-service-dialog.component';
import { CustomerRow } from '../customers.component';

@Component({
  selector: 'ip6m-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.css']
})
export class ServiceListComponent implements OnInit {
 
  ownedServices:any[]=[]
 
  @Input()services:any
  @Input()customer:CustomerRow
  constructor(
    private customerService:CustomerService,
    public navigationService:NavigationService,
    private dialog:MatDialog,
    ) { }

  editService(service)
  {
    const dialogRef = this.dialog.open(AddServiceDialogComponent, {
      data:{edit:true,
            customer:this.customer,
            service:service
      }
    });
    dialogRef.afterClosed().subscribe(result=>{
      if(result && result.event=='submit')
      {
         
         result.data.customerId=this.customer.customerId
         let service = this.services.find(service=> service.serviceId==result.data.serviceId)
         result.data.name=this.customer.name
         this.services[this.services.indexOf(service)]=result.data
         this.ownedServices[this.ownedServices.indexOf(service)]=result.data
         
        
      }
     
    })
  }  
  deleteService(service:any)
  {
    
    this.customerService.deleteService(service.serviceId).subscribe((res:any)=>{
      console.log(res)
        if(res.affected==1)
        {
           this.services.splice(this.services.indexOf(service),1);
           this.ownedServices.splice(this.ownedServices.indexOf(service),1);
        }
    })
  }

  ngOnInit(): void {
    console.log(this.services)
      for (let index = 0; index < this.services.length; index++) {
         if(this.services[index].customerId==this.customer.customerId)
         {
           
           this.ownedServices.push(this.services[index])
         }
       
      }
  }

 

  openAddServiceDialog()
  {
    const dialogRef = this.dialog.open(AddServiceDialogComponent, {
      data:{edit:false,
        customer:this.customer,
        service:null
      }
    });
    dialogRef.afterClosed().subscribe(result=>{
      if(result && result.event=='submit')
      {
         if(!result.data.error)
         {
           console.log(result.data)
           this.services.push(result.data)
           this.ownedServices.push(result.data)
         }

      }
     
    })
  }

}
