import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OntRealTimeData } from '@ip6m/types';
@Injectable({
  providedIn: 'root'
})
export class RealTimeDataService {

  constructor(private http:HttpClient) { }



  loadRealTime(ontSn:string)
  {
    return this.http.get<OntRealTimeData>("/api/v1/datacollector/"+ontSn+"/realtime");
  }
}
