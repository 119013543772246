
    <table class="expansion">
      <tr>
        <th>Line Profile</th>
        <th>Service Profile</th>
      </tr>
      <tr>
        <td>
          {{ this.ontService.ontg.hwGponDeviceOntLineProfName | buffer }}
        </td>
        <td>
          {{ this.ontService.ontg.hwGponDeviceOntServiceProfName | buffer }}
        </td>
      </table>
      <ip6m-serviceports [slot$]="slotId" [port$]="portId" [ontId$]="this.ontService.ontg.ontId"></ip6m-serviceports>
      <ip6m-add-service-port [slot$]="slotId" [port$]="portId" [ontId$]="this.ontService.ontg.ontId"></ip6m-add-service-port>
      <div fxLayout="row" fxLayoutAlign="end">
            <button (click)="clickDeactOnt(this.ontService.ontg.sn)" mat-raised-button color="warn" matTooltip="Delete ONT">
              <mat-icon>delete</mat-icon>
            </button>
      </div>
    