import { Component, OnInit } from '@angular/core';
import { OltService } from '../../services/olt/olt.service';
@Component({
  selector: 'ip6m-frameinfo',
  templateUrl: './frameinfo.component.html',
  styleUrls: ['./frameinfo.component.css']
})
export class FrameinfoComponent implements OnInit {
  oltid$: string;

  constructor(public oltService: OltService) {}

  ngOnInit(): void { }

}
