import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Oid, Slot, ApiPort, IpTrafficParamTable, Vlans } from '@ip6m/types';
import { MessageService } from '../message/message.service';

@Injectable({
  providedIn: 'root'
})
export class OltService {
  oltid: number;
  frameInfo$: Oid[] = [];
  slotList$: Slot[] = [];
  ipTrafficParamTable: IpTrafficParamTable = {};
  vlans: Vlans;

  constructor(private http: HttpClient, private messageService: MessageService) {}

  setOltid(oltid) {
    this.oltid = oltid;
    this.getFrameInfo(oltid);
    this.vlansInit(oltid);
  }

  private vlansInit(oltid: number) {
    this.http.get<Vlans>("/api/v1/olt/" + oltid + "/vlan").subscribe(value => {
      this.vlans = value;
      this.messageService.add("OltService: Olt " + oltid + " vlans loaded.");
    })
  }

  private srvPortInit(oltid: number) {
    this.http.get("/api/v1/olt/" + oltid + "/serviceportinit").subscribe(value => {
      this.messageService.add("OltService: Olt "+oltid+" service ports loaded into api.");
      this.http.get<IpTrafficParamTable>("/api/v1/olt/" + oltid + "/iptrafficparamtable").subscribe(ipTrafParamTable => {
        this.ipTrafficParamTable = ipTrafParamTable;
      })
    })
  }

  private getPorts(oltid: number) {
    this.http.get<ApiPort[]>("/api/v1/olt/"+oltid+"/port").subscribe(value => {
      value.forEach((port) => {
        const slotId = port.slotId;
        const portId = port.id;
        port.oids.forEach((oid) => {
          if(this.slotList$[slotId].hasOwnProperty('ports')) {
            if(this.slotList$[slotId].ports.hasOwnProperty(portId)) {
              this.slotList$[slotId].ports[portId][oid.name]= oid.value;
            }
          }
        })
        
        // if(this.slotList$[slotId].hasOwnProperty('ports')) {
        //  console.log(this.slotList$[slotId].ports[portId]);
        //}        
      })
    });
  }

  private getFrameInfo(oltid: number) {
    this.http.get<Oid[]>("/api/v1/olt/"+oltid+"/frame").subscribe(value => {
      this.frameInfo$.length = 0;
      this.frameInfo$.push.apply(this.frameInfo$, value);
      this.messageService.add("OltService: Olt "+oltid+" frame info fetched.");
      for(let i=0; i < parseInt(this.frameInfo$[3].value, 10); i++) {  // hwSlots
        this.slotList$.push({'hwSlotIndex': i.toString()});
      }
      this.http.get<Slot[]>("/api/v1/olt/"+oltid+"/slot").subscribe(slots => {
        for(let i=0; i<slots.length; i++) {
          const slotIndex=slots[i].hwSlotIndex;
          this.slotList$[slotIndex] = slots[i];
          // create Port array if we have ports
          if(slots[i].hasOwnProperty('portNum')) {
            this.slotList$[slotIndex].ports=[];
            for(let j=0; j<slots[i].portNum; j++) {
              this.slotList$[slotIndex].ports.push({id: j});
            }
          }
        
        }
        this.messageService.add("OltService: Olt "+oltid+" slot list fetched.");
        this.getPorts(oltid);
        this.messageService.add("OltService: Olt "+oltid+" port list fetched.");
      })
    });
  }

  getLineProfiles(oltid: number) {
    return this.http.get<string[]>("/api/v1/olt/"+oltid+"/gpon/lineprofile");
  }

  getServiceProfiles(oltid: number) {
    return this.http.get<string[]>("/api/v1/olt/"+oltid+"/gpon/serviceprofile");
  }



}
