<div id="autofound-onts" *ngIf="autoFoundOntList$; else loading">
  <h2 class="mat-h2">Autofound Onts</h2>
  <div *ngIf="isAutoFoundListUpdating$ === false; else updating">
    <div *ngIf="autoFoundOntList$.length; else noautofound">
    <table class="autofound" *ngFor="let ont of autoFoundOntList$; let i = index">
        <tr>
          <th>Customer</th>
          <th>SN</th>
          <th>RegTime</th>
        
        </tr>
        <tr>
          <td></td>
          <td>{{ ont.sn }}</td>
          <td>{{ ont.hwGponDeviceOntRegisterTime | timestampbuffer }}</td>
        </tr>
        <tr>

          <td>
            <mat-form-field >
              <mat-select #customerselect [disabled]="lineprofselect.value || serviceprofselect.value" disableOptionCentering  placeholder="Customer" [(ngModel)]="this.selectedCustomer" >

                <mat-select-trigger>
                  <span *ngIf="this.selectedCustomer">{{this.selectedCustomer.name}}</span>
               
                </mat-select-trigger>
                <mat-option>
                  <ngx-mat-select-search  placeholderLabel="Find customer..." #search></ngx-mat-select-search>
                </mat-option>
                <mat-option style="text-align: center;">- Choose -</mat-option>
                <mat-option *ngFor="let customer of this.filterCustomers(search.value,this.customers)" [value]="customer">
                  <span style="float:left">{{customer.customerId}}</span>
                  <span style="float:right">{{customer.name}}</span>
                </mat-option>
                
              </mat-select>
            </mat-form-field>
          </td>
         
          <td>
            <mat-form-field appearence="fill">
              <mat-label>Line Profile</mat-label>
              <mat-select [disabled]="customerselect.value" #lineprofselect [(value)]="selectedLineProfiles[i]">
                <mat-option *ngFor="let lp of lineProfiles$; let j=index;" value="{{ j }}">{{ lp }}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field appearence="fill">
              <mat-label>Service Profile</mat-label>
              <mat-select [disabled]="customerselect.value" #serviceprofselect [(value)]="selectedServiceProfiles[i]">
                <mat-option *ngFor="let sp of serviceProfiles$; let j=index;" value="{{ j }}">{{ sp }}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td><button (click)="clickActOnt(i)" mat-raised-button color="primary">Activate</button></td>
        </tr>
    </table>
    </div>
  </div>
</div>

<div id="activated-onts" *ngIf="ontList$; else loading">
      <h2 class="mat-h2">Activated Onts</h2>
      <div *ngIf="isOntsUpdating$ === false; else updating">
        <div *ngIf="isOntsLoaded$ else notfound">
        <mat-expansion-panel 
          *ngFor="let ont of ontList$ | keyvalue; let i = index">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="ont-num">
                {{ ont.value.ontId }} 
              </div>
              <div>
                {{ ont.key }}
              </div>
            </mat-panel-title>
            <mat-panel-description>
              {{ ont.value.hwGponDeviceOntDespt | buffer }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <table class="expansion">
              <tr>
                <th>Line Profile</th>
                <th>Service Profile</th>
              </tr>
              <tr>
                <td>
                  {{ ont.value.hwGponDeviceOntLineProfName | buffer }}
                </td>
                <td>
                  {{ ont.value.hwGponDeviceOntServiceProfName | buffer }}
                </td>
              </table>
              <ip6m-serviceports [slot$]="slotId" [port$]="portId" [ontId$]="ont.value.ontId"></ip6m-serviceports>
              <ip6m-add-service-port [slot$]="slotId" [port$]="portId" [ontId$]="ont.value.ontId"></ip6m-add-service-port>
              <div fxLayout="row" fxLayoutAlign="end">

                  <button (click)="clickDeactOnt(ont.key)" mat-raised-button color="warn" matTooltip="Delete ONT">
                    <mat-icon>delete</mat-icon>
                  </button>
              </div>
          </ng-template>
        
        </mat-expansion-panel>
      </div>
    </div>
  </div>
  

<ng-template #loading>
  <div class="loading">
    <div>Loading ONTs...</div>
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
</ng-template>

<ng-template #updating>
  <div class="updating">
    <div>Updating ONTs...</div>
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
</ng-template>


<ng-template #noautofound>
    <div class="notfound">No autofound ONTs.</div>
</ng-template>

<ng-template #notfound>
    <div class="notfound">No ONTs activated.</div>
</ng-template>