
<div  class="container">
    <div  *ngIf="this.ownedServices.length">
    <div  class="grid" style="border-bottom: white 1px solid;margin-bottom:20px">
        <div>ONT SN</div>
        <div>Service ID</div>
        <div>Service Type</div>
        <div>Zipcode</div>
        <div>City</div>
        <div>Street</div>
        <div>House Number</div>
        <div></div>
    </div>

    <div *ngFor="let service of this.ownedServices" class="grid" id="services" (click)="this.navigationService.openOnt(service.ontSn)">
        <div>{{service.ontSn}}</div>
        <div>{{service.serviceId}}</div>
        <div>{{service.serviceType.name}}</div>
        <div>{{service.zipcode}}</div>
        <div>{{service.city.name}}</div>
        <div>{{service.street.name}}</div>
        <div>{{service.houseNum}}</div>
        <button mat-fab style="background-color: transparent; color:white" (click)="deleteService(service)">
            <mat-icon class=center>delete</mat-icon>
        </button>
        <button mat-fab style="position:absolute;right:12%; background:transparent; color:white" (click)="editService(service)">
            <mat-icon class=center>edit</mat-icon>
        </button>
    </div>
</div>
    <button mat-flat-button class="addService" (click)="openAddServiceDialog()">
        
            Add Service
       
    </button>
</div>
