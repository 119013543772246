import { splitNsName } from '@angular/compiler';
import { Pipe, PipeTransform } from '@angular/core';
import { snmpBuffer } from '@ip6m/types';

@Pipe({
  name: 'timestampbuffer'
})
export class TimestampbufferPipe implements PipeTransform {

  transform(value: snmpBuffer, ...args: unknown[]): unknown {
    const Y = 256 * value.data[0] + value.data[1];
    const M = value.data[2].toString().padStart(2, '0');
    const D = value.data[3].toString().padStart(2, '0');
    const h = value.data[4].toString().padStart(2, '0');
    const m = value.data[5].toString().padStart(2, '0');
    const s = value.data[6].toString().padStart(2, '0');
    
    return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s;
  }

}
