<form [formGroup]="addSrvPortForm" (ngSubmit)="onSubmit()">
  <mat-card *ngIf="isActive$; else add_button">
    <mat-card-title>Add Service Port</mat-card-title>
    <mat-card-content>
      <mat-form-field appearance="fill">
        <mat-label>Service Port Type</mat-label>
        <mat-select formControlName="srvPortTypeControl" (selectionChange)="typeChanged()">
          <mat-option *ngFor="let type of srvPortTypes" [value]="type.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div
        *ngIf="isPortReqd"
        fxLayout="row"
        fxLayoutAlign="space-between"
        formGroupName="port"
        >
        <mat-form-field appearence="fill">
          <mat-label>Gem Port</mat-label>
          <mat-select formControlName="gemPortControl">
            <mat-option [value]=0>0</mat-option>
            <mat-option [value]=1>1</mat-option>
            <mat-option [value]=2>2</mat-option>
            <mat-option [value]=3>3</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearence="fill">
          <mat-label>Lan Port</mat-label>
          <mat-select formControlName="lanPortControl">
            <mat-option [value]=0>0</mat-option>
            <mat-option [value]=1>1</mat-option>
            <mat-option [value]=2>2</mat-option>
            <mat-option [value]=3>3</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

        <h4 mat-subheader>Traffic Profiles</h4>
        <div
          fxLayout="row"
          fxLayoutAlign="space-between"
          formGroupName="bandWidthProfile"
        >
          <mat-form-field appearence="fill">
            <mat-label>Upstream traffic profile</mat-label>
            <mat-select formControlName="upStreamProfileControl">
              <mat-option
                *ngFor="let prof of oltService.ipTrafficParamTable | keyvalue"
                [value]="prof.key"
              >
                {{ prof.key }}: {{ prof.value.hwIpTrafficName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearence="fill">
            <mat-label>Downstream traffic profile</mat-label>
            <mat-select formControlName="downStreamProfileControl">
              <mat-option
                *ngFor="let prof of oltService.ipTrafficParamTable | keyvalue"
                [value]="prof.key"
              >
                {{ prof.key }}: {{ prof.value.hwIpTrafficName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      
      <h4 mat-subheader>Network side</h4>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between"
        formGroupName="networkSide"
      >
        <mat-form-field appearence="fill">
          <mat-label>Vlan ID</mat-label>
          <mat-select formControlName="vlanIdControl" required>
            <mat-option [value]="null"></mat-option>
            <mat-option
              *ngFor="let vlan of oltService.vlans | keyvalue"
              [value]="vlan.key"
            >
              {{ vlan.key }}
              <span *ngIf="vlan.value.descr"> - {{ vlan.value.descr }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearence="fill">
          <mat-label>Vlan tag transform</mat-label>
          <mat-select formControlName="vlanTagTransformControl">
            <mat-option *ngFor="let tt of tagTransforms" [value]="tt.value">
              {{ tt.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <h4 mat-subheader>User side</h4>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between"
        formGroupName="userSide"
      >
        <mat-form-field appearence="fill">
          <mat-label>User Vlan Tag</mat-label>
          <mat-select formControlName="userVlanTagControl">
            <mat-option [value]="65535">Untagged</mat-option>
            <mat-option [value]="0">Vlan Id:</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearence="fill">
          <mat-label>User Vlan ID</mat-label>
          <input
            formControlName="userVlanIdControl"
            type="number"
            matInput
            placeholder="1..4096"
          />
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-actions fxLayout="row" fxLayoutAlign="space-evenly">
      <button
        type="submit"
        mat-raised-button
        color="primary"
        [disabled]="!addSrvPortForm.valid"
        i18n
      >
        Add
      </button>
      <button (click)="clickCancel()" mat-raised-button color="accent" i18n>
        Cancel
      </button>
    </mat-card-actions>
  </mat-card>
</form>

<ng-template #add_button>
  <div class="add-srv-port-container" fxLayout="row" fxLayoutAlign="center">
    <button
      (click)="clickAddSrvPort()"
      mat-mini-fab
      color="primary"
      matTooltip="Add service port"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
</ng-template>

<p>From Status: {{ addSrvPortForm.status }}</p>
<p>From Value: {{ addSrvPortForm.getRawValue() | json }}</p>
