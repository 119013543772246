import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Street } from '@ip6m/types';
import { json } from 'express';
import { CustomerService } from '../../../services/customer/customer.service';

@Component({
  selector: 'ip6m-add-street-dialog',
  templateUrl: './add-street-dialog.component.html',
  styleUrls: ['./add-street-dialog.component.css']
})
export class AddStreetDialogComponent implements OnInit {
  form: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<AddStreetDialogComponent>,
    private fb: FormBuilder,
    private customerService:CustomerService,
    @Inject(MAT_DIALOG_DATA) public data,
    
  ) { }


  submit()
  {

    console.log(this.form.value)
    if(this.form.valid)
    {
      this.customerService.newStreet(this.form.value).subscribe((resp:any)=>{
        if(!resp.error)
        {
          console.log("SUCCESSFUL STREET CREATION")
          this.dialogRef.close({event:'submit',data:resp})
        }
        else{
          alert(resp.error)
        }
        
        
      })
    }
  }
  getCityString(cityId):string
  {
    
    for (let index = 0; index < this.data.cities.length; index++) {
      if(this.data.cities[index].cityId==cityId){
        return this.data.cities[index].name;
      }
      
    }
   
  }
  
  filterCitiesAndStreets(filter:string,arr:any[])
  {
    let filtered=[]
    for (let index = 0; index < arr.length; index++) {
      if(arr[index].name.toLowerCase().includes(filter.toLowerCase()))
      {
        filtered.push(arr[index])
      }
    }
    return filtered
  }
  
  ngOnInit(): void {
    this.form = this.fb.group({
      name: [
        null, [Validators.required]
      ],
      cityId: [
        null, [Validators.required]
      ],
    });
    this.form.patchValue({
      cityId:this.data.cityId? this.data.cityId : null
    });
  }

  CancelClick() {
    this.dialogRef.close();
  }


}
