import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CustomerService } from '../../../services/customer/customer.service';
import { City, CustomerData, CustomerRaw, Ont, OntsBySerialNum, ServiceType, Street } from '@ip6m/types';
import { filter } from 'rxjs';

import { AddStreetDialogComponent } from '../add-street-dialog/add-street-dialog.component';
import { OltlistService } from '../../../services/oltlist/oltlist.service';
import { HttpClient } from '@angular/common/http';
import { DatacollectorService } from '../../../services/datacollector/datacollector.service';

@Component({
  selector: 'ip6m-add-service-dialog',
  templateUrl: './add-service-dialog.component.html',
  styleUrls: ['./add-service-dialog.component.css']
})
export class AddServiceDialogComponent implements OnInit {

  form: FormGroup;
  cities:City[]=[]
  useBilling:boolean=false;
  streets:Street[]=[]
  serviceTypes:ServiceType[]=[]
  onts:Ont[]=[]
  selectedOntSn:string
  @ViewChild('citySelector') citySelector;
  
  
  constructor(
    public dialogRef: MatDialogRef<AddServiceDialogComponent>,
    private fb: FormBuilder,
    private customerService: CustomerService,
    private dialog:MatDialog,
    private oltListService:OltlistService,
    private http:HttpClient,
    private dataCollectorService:DatacollectorService,
    @Inject(MAT_DIALOG_DATA) public input,

    
  ) {}


  submit()
  {
    if(this.form.valid)
    {
      if(!this.input.edit)
      {
        console.log(this.form.value)
        
        this.customerService.newService(this.form.value,this.input.customer.customerId).subscribe((resp)=>{
          console.log("SUCCESSFUL SERVICE CREATION")
         
          this.dialogRef.close({event:'submit',data:resp})
          
        })
      }
      else{
        
        this.customerService.updateService(this.form.value,this.input.service.serviceId).subscribe((resp)=>{
          console.log(resp)
          this.dialogRef.close({event:'submit',data:resp})
          
        })
      }
      
    }
   
  }

 
  getCityString():string
  {
    
    for (let index = 0; index < this.cities.length; index++) {
      if(this.cities[index].cityId==this.form.get('cityId').value){
        return this.cities[index].name;
      }
      
    }
   
  }
  getStreetString():string
  {
    
    for (let index = 0; index < this.streets.length; index++) {
      if(this.streets[index].streetId==this.form.get('streetId').value){
        return this.streets[index].name;
      }
      
    }
   
  }
  getServiceTypeString():string
  {
    
    for (let index = 0; index < this.serviceTypes.length; index++) {
      if(this.serviceTypes[index].serviceTypeId==this.form.get('serviceTypeId').value){
        return this.serviceTypes[index].name;
      }
      
    }
   
  }

  openStreetsDialog()
  {
    let cityId=this.form.get('cityId').value
    const dialogRef = this.dialog.open(AddStreetDialogComponent, {
      data:{
        cityId:cityId,
        cities:this.cities,
        streets:this.streets
      }

    });
    dialogRef.afterClosed().subscribe(result=>{
      if(result && result.event=='submit')
      {
        this.streets.push(result.data)
        this.form.patchValue({
          cityId:result.data.cityId,
          streetId: result.data.streetId,
          
        });

      }
      else if (result && result.event=="choice")
      {
        this.form.patchValue({
          streetId: result.data.streetId,
          
        });
      }
    })
  }

  useBillingAddress(checked)
  {
    
   
    
    this.customerService.getStreetsByName(this.input.customer.street).subscribe((street)=>{
      
      this.form.patchValue({
        cityId: checked ? this.cities.filter(obj => {
          return obj.name==this.input.customer.city
        })[0].cityId : null
        
      });
      this.form.patchValue({
        streetId: checked ? street.streetId : null,
        houseNum:checked? this.input.customer.houseNum:null,
        zipcode:checked? this.input.customer.zipcode:null,
        apartment:checked? this.input.customer.apartment:null,
        gpsLatitude:checked?this.input.customer.gpsLatitude?this.input.customer.gpsLatitude:null:null,
        gpsLongitude:checked?this.input.customer.gpsLongitude?this.input.customer.gpsLongitude:null:null
        
      });
    })
  
    
  }

  getStreets(cityId:number)
  {
    this.form.patchValue({
      streetId: null,
      
    });
    this.customerService.getStreets(cityId).subscribe(streets=>this.streets=streets )
  }

  ngOnInit(): void {
    this.dataCollectorService.getOnts().subscribe((onts)=>{
      Object.values(onts).forEach((ont)=>{
        if(!ont.customer)
        {
          this.onts.push(ont)
        }
      })
  
    })

    
    this.customerService.getServiceTypes().subscribe(serviceTypes=>this.serviceTypes=serviceTypes )
    this.customerService.getCities().subscribe(cities=>this.cities=cities )
   
    
      
      
    
    this.form = this.fb.group({
      
      ontSerialNum: [
        null, [Validators.required]
      ],
      houseNum:[
        null, [Validators.required]
      ],
      apartment:[
        null, []
      ],
      zipcode:[
        null, [Validators.required]
      ],
      gpsLongitude:[
        null, []
      ],
      gpsLatitude:[
        null, []
      ],
      serviceTypeId:[
        null, [Validators.required]
      ],
      cityId:[
        null, [Validators.required]
      ],
      streetId:[
        null, [Validators.required]
      ],
     
     
    });
   

    if(this.input.edit)
    {
        this.streets=[]
        this.customerService.getStreets(this.input.service.city.cityId).subscribe((resp)=>{
          this.streets=resp
        })
        let id =setInterval(()=>{
          if(this.cities.length && this.serviceTypes.length && this.streets.length )
          {
              this.form.patchValue({
                cityId: this.input.service.city.cityId,
                
              });
              console.log(this.input.service)
              this.form.patchValue({
                streetId: this.input.service.street.streetId,
                gpsLatitude:this.input.service.gpsLatitude,
                gpsLongitude:this.input.service.gpsLongitude,
                houseNum:this.input.service.houseNum,
                ontSerialNum:this.input.service.ontSn,
                zipcode:this.input.service.zipcode,
                apartment:this.input.service.apartment,
                serviceTypeId:this.input.service.serviceType.serviceTypeId
              });
          
            clearInterval(id)
          }

        
        
      },50)
       
        
       
    
      
    }
   
  }
  
  filterOnts(filter:string,arr:Ont[])
  {
    let filtered=[]
    for (let index = 0; index < arr.length; index++) {
      if(arr[index].sn.toLowerCase().includes(filter.toLowerCase()))
      {
        filtered.push(arr[index])
      }
    }
    return filtered
  }

   filter(filter:string,arr:any[])
  {
    let filtered=[]
    for (let index = 0; index < arr.length; index++) {
      if(arr[index].name.toLowerCase().includes(filter.toLowerCase()))
      {
        filtered.push(arr[index])
      }
    }
    return filtered
  }
  
  

 

  CancelClick() {
    this.dialogRef.close();
  }
  
}



