<mat-list dense>
    <mat-list-item class="oid-list-item" *ngFor="let oid of oltService.frameInfo$">
        <div class="oid-info-row">
            <span class="oid-human">{{ oid.name }}:</span>
            <span class="oid-value">{{ oid.value | buffer}}</span>
        </div>
    </mat-list-item>
</mat-list>


