import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vlanid'
})
export class VlanidPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    if (value == 65535) {
      return 'untagged';
    }
    return value;
  }

}
