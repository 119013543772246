import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Ont, OntRealTimeData } from '@ip6m/types';
import { OntpagedetailComponent } from '../../components/ontpagedetail/ontpagedetail.component';
import { DatacollectorService } from '../../services/datacollector/datacollector.service';
import { MarkerService } from '../../services/marker/marker.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { OltlistService } from '../../services/oltlist/oltlist.service';
import { OntService } from '../../services/ont/ont.service';
import { RealTimeDataService } from '../../services/realtime/real-time-data.service';
import { ONT_t } from '../map/map.component';

@Component({
  selector: 'ip6m-ont',
  templateUrl: './ont.component.html',
  styleUrls: ['./ont.component.css'],
})
export class OntComponent implements OnInit {
  
  
  public sn:string
  constructor(
    private route: ActivatedRoute,
    public ontService: OntService,
    private router:Router
   
  ) {}

 

  ngOnInit() {


    this.route.paramMap.subscribe(params => {
      this.sn= params.get('sn');
      if(!this.sn || this.sn=="")this.router.navigateByUrl('/dashboard')
      this.ontService.getOnt(this.sn);
      setTimeout(()=>{
        console.log(this.ontService.loadingCode)
      })
     
    });
    
    
    
  }

  
}
