<table *ngIf="oltService.slotList$.length">
    <tr *ngFor="let slot of oltService.slotList$" [ngClass]="opStat(slot)" matTooltip="Slot operstate is {{ opStat(slot) }}.">
        <td class="index">{{ slot.hwSlotIndex }} </td>
        <td class="type"> 
            <div *ngIf="slot.hwSlotTypeName">{{ slot.hwSlotTypeName | buffer }}
                <div *ngIf="slot.hwSlotPhySerialNum" class="serialnum">(sn: {{ slot.hwSlotPhySerialNum | buffer }}) </div>
            </div>
        </td>
        <td>
            <ip6m-ports *ngIf="slot.portNum" [slotId$]="slot.hwSlotIndex"></ip6m-ports>
        </td>
    </tr>
</table>
