
<div class="controls">
    <mat-form-field appearance="standard">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>

    <div class="control-item">
      <button mat-mini-fab color="primary"
              matTooltip="Add New Customer" 
              aria-label="Add New Customer"
              (click)="openAddCustomerDialog()">
        <mat-icon>add</mat-icon>
      </button> 
    </div>
</div>

<mat-tab-group>
  <mat-tab *ngFor="let db of databases$" label="{{ db.title}}">

    <div class="mat-elevation-z8" *ngIf="db.title=='Local Users'">
      <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>

      <ng-container  *ngFor="let col of displayedColumns" matColumnDef="{{ col }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ col }} </th>
        <td  class="row"  mat-cell *matCellDef="let element" (click)="element.isExpanded = !element.isExpanded" > {{element[col]}}
          <div  style="float:right" *ngIf="col==this.displayedColumns[this.displayedColumns.length-1]">
            <button mat-button (click)="this.editCustomer(element)">
              <mat-icon>edit</mat-icon>
            </button>
          </div>  
        </td>
      </ng-container>




      <ng-container  matColumnDef="expandedDetail">
        <td   mat-cell *matCellDef="let element" [attr.colspan]="100" >

          <div  class="row student-element-detail"   [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
            
                <ip6m-service-list  *ngIf="element.isExpanded" [customer]="element" [services]="services"></ip6m-service-list>
          

          </div>

        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>

      
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"  style="height: 0px;" class="student-detail-row"></tr>



      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow >
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator #MatPaginator1="matPaginator"   [pageSizeOptions]="[5, 50, 500, 5000]" aria-label="Select page of users"></mat-paginator>
  </div>



  <div class="mat-elevation-z8" *ngIf="db.title=='Giganet'">
    <table mat-table [dataSource]="giganetDataSource" matSort multiTemplateDataRows>

      <ng-container  *ngFor="let col of this.giganetColumns" matColumnDef="{{ col }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ col }} </th>
        <td  class="row"  mat-cell *matCellDef="let element" > {{element[col]}}</td>
      </ng-container>




     


      <tr mat-header-row *matHeaderRowDef="giganetColumns" ></tr>
      <tr mat-row *matRowDef="let row; columns: giganetColumns;"  (click)="this.navigationService.openOnt(row.ontSn)"></tr>

      
      



      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow >
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator  #MatPaginator2="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
   
  </div>
  </mat-tab>
</mat-tab-group>