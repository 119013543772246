import { Pipe, PipeTransform } from '@angular/core';
import { srvPortType } from '@ip6m/types';

@Pipe({
  name: 'srvporttype'
})
export class SrvPortTypePipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    return srvPortType[value];
  }

}
