import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Ont } from '@ip6m/types';
import { CacheService } from '../../services/cache/cache.service';
import { DatacollectorService } from '../../services/datacollector/datacollector.service';
import { MarkerService } from '../../services/marker/marker.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { OltlistService } from '../../services/oltlist/oltlist.service';
import { OntService } from '../../services/ont/ont.service';
import { TransformsService } from '../../services/transforms/transforms.service';

interface WCOnt
{
  ontSn:string
  status:string
  location:string
}

@Component({
  selector: 'ip6m-wrong-coordiante-ontlist',
  templateUrl: './wrong-coordinate-ontlist.component.html',
  styleUrls: ['./wrong-coordinate-ontlist.component.css']
})
export class WrongCoordinateOntlistComponent implements OnInit {
  public wrongCoordOntsStore:WCOnt[]=[]
  public wrongCoordOnts:WCOnt[]=[]
  wrongCoordianteOntsColumns: string[] = ['ontSn','status','location',' '];
  public wrong:boolean=true;
  public noDist:boolean=true;
  public notSet:boolean=true;
  public loaded:boolean=false;



  wrongCoordOntDatasource = new MatTableDataSource<WCOnt>(this.wrongCoordOnts);
  constructor(
    private markerService:MarkerService,
    private oltListService:OltlistService,
    private transformService:TransformsService,
    public navigationService:NavigationService
  ) { }
  @ViewChild(MatSort) sort: MatSort;
  print(param)
  {
    console.log(param)
  }


  order()
  {
    setTimeout(()=>{
      this.wrongCoordOnts=[]
      
         for (let index = 0; index < this.wrongCoordOntsStore.length; index++) {
           if((this.wrongCoordOntsStore[index].status=="Coordinates not set" && this.notSet) ||
           (this.wrongCoordOntsStore[index].status=="Wrong coordinates" && this.wrong) ||
           (this.wrongCoordOntsStore[index].status=="Could not get distance from OLT" && this.noDist))
           {
             this.wrongCoordOnts.push(this.wrongCoordOntsStore[index]);
           }
         }
         this.wrongCoordOntDatasource = new MatTableDataSource<WCOnt>(this.wrongCoordOnts);
         this.wrongCoordOntDatasource._updateChangeSubscription();
      
    },100)
   
  }

  ngOnInit(): void {
    
    let id =setInterval(()=>{

   
      if(this.markerService.ontsLoaded)
      {
      
      Object.values(this.markerService.ontsB).forEach((ont) => {
        
        if(ont.customer)
        {
          let slotPort=this.transformService.getSlotPort(ont.ifIndex)
          let tmp:WCOnt={
            ontSn:ont.sn,
            status:"",
            location:this.oltListService.oltList[ont.oltId].name+" 0/"+slotPort[0]+"/"+slotPort[1],
          }
         
          var distance = this.getDistance([ont.customer.gpsLatitude, ont.customer.gpsLongitude],
                                      [this.oltListService.oltList[ont.oltId].gpsLatitude, this.oltListService.oltList[ont.oltId].gpsLongitude])
          if(ont['hwXponOntLastDistance']<=0)
          {
          tmp.status="Could not get distance from OLT"
          this.wrongCoordOnts.push(tmp)
          this.wrongCoordOntsStore.push(tmp)
          }
          else if(!ont.customer.gpsLatitude || (ont.customer.gpsLatitude==0 && ont.customer.gpsLongitude==0))
          {
            tmp.status="Coordinates not set"
            this.wrongCoordOnts.push(tmp)
            this.wrongCoordOntsStore.push(tmp)
           

          }
          else if(distance >ont['hwXponOntLastDistance'])
          {
            tmp.status="Wrong coordinates"
            this.wrongCoordOnts.push(tmp)
            this.wrongCoordOntsStore.push(tmp)
            

          }
         
          
        }

        
        
      });
    
     
      

     
     
      
      this.wrongCoordOntDatasource = new MatTableDataSource<WCOnt>(this.wrongCoordOnts);
        this.wrongCoordOntDatasource._updateChangeSubscription();
        this.wrongCoordOntDatasource.sort=this.sort
        clearInterval(id)
        this.loaded=true;
        console.log("loaded")
      }

     
  },50)
  } 

   getDistance(origin, destination) {
    // return distance in meters
    var lon1 = this.toRadian(origin[1]),
        lat1 = this.toRadian(origin[0]),
        lon2 = this.toRadian(destination[1]),
        lat2 = this.toRadian(destination[0]);

    var deltaLat = lat2 - lat1;
    var deltaLon = lon2 - lon1;

    var a = Math.pow(Math.sin(deltaLat/2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon/2), 2);
    var c = 2 * Math.asin(Math.sqrt(a));
    var EARTH_RADIUS = 6371;
    return c * EARTH_RADIUS * 1000;
}
toRadian(degree) {
    return degree*Math.PI/180;
}


}
