import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { OLT, Ont } from '@ip6m/types';
import { MarkerService } from '../../services/marker/marker.service';
import { OltlistService } from '../../services/oltlist/oltlist.service';
import { OntService } from '../../services/ont/ont.service';
import { TransformsService } from '../../services/transforms/transforms.service';
interface unregOnt {
  ontSn: string;
  oltName: string;
  oltSlot: string;
  oltPort: string;
}
@Component({
  selector: 'ip6m-autofoundlist',
  templateUrl: './autofoundlist.component.html',
  styleUrls: ['./autofoundlist.component.css'],
})
export class AutofoundlistComponent implements OnInit {
  oltList$: OLT[];
  autoFoundColumns: string[] = ['ontSn', 'oltName', 'oltSlot', 'oltPort'];
  public autoFoundOnts: unregOnt[] = [];
  autoFoundDataSource = new MatTableDataSource<unregOnt>(this.autoFoundOnts);

  constructor(
    private oltlistService: OltlistService,
    private http: HttpClient,
    private makerService: MarkerService,
    private transformService:TransformsService
  ) {}

  ngOnInit(): void {
    //ask for autofounds until we get them
    let id2 = setInterval(() => {
      if (this.oltlistService.oltList) {
        this.oltList$ = this.oltlistService.oltList;

        for (let index = 0; index < this.oltList$.length; index++) {
          if(this.oltList$[index].datacollector)
          {
          this.http
            .get<Ont[]>('/api/v1/olt/' + index + '/gpon/ont/autofound')
            .subscribe((value) => {
              Object.values(value).forEach((c: Ont) => {
                let slotPort = this.transformService.getSlotPort(c['ifIndex']);
                this.autoFoundOnts.push({
                  ontSn: c['sn'].toUpperCase(),
                  oltName: this.oltlistService.oltList[c['oltId']].name,
                  oltSlot: slotPort[0].toString(),
                  oltPort: slotPort[1].toString(),
                });
              });

              this.autoFoundDataSource = new MatTableDataSource<unregOnt>(
                this.autoFoundOnts
              );
              this.autoFoundDataSource._updateChangeSubscription();
            },
            err=>{
              console.log("Error at getting autofounds on OLT: "+this.oltList$[index].name)
            });
          }
        }

        clearInterval(id2);
      }
    }, 2000);
  }
}
