import { Pipe, PipeTransform } from '@angular/core';
import { snmpBuffer } from '@ip6m/types';

@Pipe({
  name: 'buffer'
})
export class BufferPipe implements PipeTransform {

  transform(value: snmpBuffer, ...args: unknown[]): unknown {
    if(value && value.hasOwnProperty('data')) {
      const buffer = new Uint8Array(value.data);
      if(buffer.length === 0 && args[0]) {
        return args[0];
      } else {        
        return new TextDecoder().decode(buffer);
      }
    } else {
        return value;  
    }
  }
}
