import { Component, Input, OnInit } from '@angular/core';
import { City, Ont, OntsBySerialNum, ServiceType, Street } from '@ip6m/types';
import { CustomerRow } from '../../pages/customers/customers.component';
import { CustomerService } from '../../services/customer/customer.service';
import { OltService } from '../../services/olt/olt.service';
import { OntService } from '../../services/ont/ont.service';

@Component({
  selector: 'ip6m-ontlist',
  templateUrl: './ontlist.component.html',
  styleUrls: ['./ontlist.component.css']
})
export class OntlistComponent implements OnInit {
  @Input() slotId: number;
  @Input() portId: number;
  
  lineProfiles$: string[];
  serviceProfiles$: string[];
  autoFoundOntList$: Ont[];
  ontList$: OntsBySerialNum;
  isOntsLoaded$ = false;
  isOntsUpdating$ = false;
  isAutoFoundListUpdating$ = false;
  selectedLineProfiles: number[] = [];    // for form binding
  selectedServiceProfiles: number[] = []; // for form binding
  customers: CustomerRow[] = []; // for form binding
  selectedCustomer:CustomerRow
  
  
  constructor(
     public oltService: OltService,
     private ontService: OntService,
     private customerService:CustomerService
     ) {}

  ngOnInit(): void {
    this.ontService.getAutoFoundOnts(this.oltService.oltid, this.slotId, this.portId).subscribe(value => {
      this.autoFoundOntList$ = value;
    })
    this.ontService.getOnts(this.oltService.oltid, this.slotId, this.portId).subscribe(value => {
      this.ontList$ = value;
      if(Object.keys(this.ontList$).length) {
        this.isOntsLoaded$ = true;

        console.log(this.ontList$)
      }
    })
    this.oltService.getLineProfiles(this.oltService.oltid).subscribe(value => {
      this.lineProfiles$ = value;
    })
    this.oltService.getServiceProfiles(this.oltService.oltid).subscribe(value => {
      this.serviceProfiles$ = value;
    })

    this.customerService.getCustomers().subscribe((customers)=>{
      this.customers=customers
    })
  }
  
 
  filterCustomers(filter:string,arr:CustomerRow[])
  {
    let filtered=[]
    for (let index = 0; index < arr.length; index++) {
      if(arr[index].name.toLowerCase().includes(filter.toLowerCase())||
      arr[index].customerId.toString().toLowerCase().includes(filter.toLowerCase())
      )
      {
        filtered.push(arr[index])
      }
    }
    return filtered
  }
  
  

  clickActOnt(id: number) {
    this.isOntsUpdating$ = true;
    this.isOntsLoaded$ = false;
    const selectedLineProfileId = this.selectedLineProfiles[id];
    const selectedServiceProfileId = this.selectedServiceProfiles[id];
    const profiles = {
      lp: this.lineProfiles$[selectedLineProfileId],
      sp: this.serviceProfiles$[selectedServiceProfileId]
    }
    this.ontService.activateOnt(this.oltService.oltid, this.autoFoundOntList$[id], profiles).subscribe(value => {
      if(value.hasOwnProperty('activated') && value['activated']) {
        
        if(this.selectedCustomer) this.newService(id,this.selectedCustomer)
       
        this.autoFoundOntList$.splice(id, 1); // remove ONT from autofound list
        setTimeout (() => {
          this.ontService.getOnts(this.oltService.oltid, this.slotId, this.portId).subscribe(value => {
            this.isOntsUpdating$ = false;
            this.ontList$ = { ...value };  // refresh ont list
            if(Object.keys(this.ontList$).length) {
              this.isOntsLoaded$ = true;
            }
          })
        }, 10000);
      }      
    });
  }

  
  clickDeactOnt(sn: string) {
    this.ontService.deactivateOnt(this.oltService.oltid, this.ontList$[sn]).subscribe(value => {
      delete this.ontList$[sn];
      if(Object.keys(this.ontList$).length === 0) {
        this.isOntsLoaded$ = false;
      }
      this.isAutoFoundListUpdating$ = true;
      setTimeout (() => {
        this.ontService.getAutoFoundOnts(this.oltService.oltid, this.slotId, this.portId).subscribe(value => {
          this.isAutoFoundListUpdating$ = false;
          this.autoFoundOntList$ = value;
        });
      }, 8000); // It takes some seconds to autofind the deleted ont.
    })
  }


  newService(id:number,customer:CustomerRow)
  {
      let newService={
      
        ontSerialNum: this.autoFoundOntList$[id].sn.toUpperCase(),
        serviceTypeId: 0,
        streetId: 0,
        cityId: 0,
        houseNum: customer.houseNum,
        zipcode: customer.zipcode,
        apartment: customer.apartment,
 
      }
     this.customerService.getCities().subscribe((cities:City[])=>{
        for (let index = 0; index < cities.length; index++) {
           if(cities[index].name==customer.city)
           {
             newService.cityId=cities[index].cityId
             break;
           }
           
        }
        this.customerService.getStreets(newService.cityId).subscribe((streets:Street[])=>{
            for (let index = 0; index < streets.length; index++) {
              if(streets[index].name==customer.street)
              {
                
                newService.streetId=streets[index].streetId
                break;
              }
              
            }
            this.customerService.getServiceTypes().subscribe((serviceTypes:ServiceType[])=>{
              newService.serviceTypeId=serviceTypes[0].serviceTypeId
              this.customerService.newService(newService,customer.customerId).subscribe((resp)=>{
                console.log(resp)
              })

            })
            
            
        })
     })
   
    
  }
}
