import { Pipe, PipeTransform } from '@angular/core';
import { tagTransform } from '@ip6m/types';

@Pipe({
  name: 'tagtransform'
})
export class TagtransformPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return tagTransform[value];
  }

}
