import { Component, OnInit, ViewChild,Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CustomerService } from '../../../services/customer/customer.service';
import { City, CustomerRaw, ServiceType, Street } from '@ip6m/types';
import { filter } from 'rxjs';
import { AddStreetDialogComponent } from '../add-street-dialog/add-street-dialog.component';
@Component({
  selector: 'ip6m-add-customer-dialog',
  templateUrl: './add-customer-dialog.component.html',
  styleUrls: ['./add-customer-dialog.component.css'],
})
export class AddCustomerDialogComponent implements OnInit {
 
  form: FormGroup;
  cities:City[]=[]
  streets:Street[]=[]
  @ViewChild('citySelector') citySelector;
  
  
  constructor(
    public dialogRef: MatDialogRef<AddCustomerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public editMode: any,
    private fb: FormBuilder,
    private customerService: CustomerService,
    private dialog:MatDialog,
    
    
    
  ) {}

   makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}


  submit()
  {
    console.log("HE")

    /*let f ={
      apartment: this.makeid(2),
      cityId: 58,
      company: false,
      houseNum: this.makeid(2),
      name: this.makeid(9),
      streetId: 21,
      zipcode: this.makeid(4),
    }
    for (let index = 0; index < 50000; index++) {
      this.customerService.newCustomer(f).subscribe((resp)=>{})
        
      
    }*/



    if(this.form.valid)
    {
      if(!this.editMode.edit)
      {
       
        this.customerService.newCustomer(this.form.value).subscribe((resp)=>{
          console.log("SUCCESSFUL CUSTOMER CREATION")
         
          this.dialogRef.close({event:'submit',data:resp})
          
        })
      }
      else{
        this.customerService.updateCustomer(this.form.value,this.editMode.customer.customerId).subscribe((resp)=>{
          if(resp.customerId)
          {
            this.dialogRef.close({event:'submit',data:resp})
          }
         
         
        })
       
      }
      
    }
   
  }

 
  getCityString():string
  {
    
    for (let index = 0; index < this.cities.length; index++) {
      if(this.cities[index].cityId==this.form.get('cityId').value){
        return this.cities[index].name;
      }
      
    }
   
  }
  getStreetString():string
  {
    
    for (let index = 0; index < this.streets.length; index++) {
      if(this.streets[index].streetId==this.form.get('streetId').value){
        return this.streets[index].name;
      }
      
    }
   
  }

  openStreetsDialog()
  {
    let cityId=this.form.get('cityId').value
    const dialogRef = this.dialog.open(AddStreetDialogComponent, {
      data:{
        cityId:cityId,
        cities:this.cities,
        streets:this.streets
      }

    });
    dialogRef.afterClosed().subscribe(result=>{
      if(result && result.event=='submit')
      {
        this.streets.push(result.data)
        this.form.patchValue({
          cityId:result.data.cityId,
          streetId: result.data.streetId,
          
        });

      }
      else if (result && result.event=="choice")
      {
        this.form.patchValue({
          streetId: result.data.streetId,
          
        });
      }
    })
  }

  getStreets(cityId:number)
  {
    this.form.patchValue({
      streetId: null,
      
    });
    this.customerService.getStreets(cityId).subscribe(streets=>this.streets=streets )
  }

  ngOnInit(): void {
    
    this.customerService.getCities().subscribe(cities=>this.cities=cities )
    
      
      
    
    this.form = this.fb.group({
      
      name: [
        null, [Validators.required]
      ],
      company:[false], 
      houseNum:[
        null, [Validators.required]
      ],
      apartment:[
        null, []
      ],
      zipcode:[
        null, []
      ],
      cityId:[
        null, [Validators.required]
      ],
      streetId:[
        null, [Validators.required]
      ],

      
     
     
    });

   
    if(this.editMode.edit)
    {
        let id =setInterval(()=>{
          if(this.cities.length)
          {
              for (let index = 0; index < this.cities.length; index++) {
                if(this.cities[index].name==this.editMode.customer.city)
                {
                
                  this.customerService.getStreets(this.cities[index].cityId).subscribe(streets=>
                    {
                      for (let j = 0; j < streets.length; j++) {
                      
                        if(streets[j].name==this.editMode.customer.street)
                        {
                          this.form.patchValue({
                            cityId: this.cities[index].cityId,
                            
                          });
                          this.form.patchValue({
                            streetId: streets[j].streetId,
                            name:this.editMode.customer.name,
                            houseNum:this.editMode.customer.houseNum,
                            apartment:this.editMode.customer.apartment,
                            zipcode:this.editMode.customer.zipcode,
                            company:this.editMode.customer.company
                          });
                          

                          break;
                        }
                      }
                    })

                  break;
                }
                
              }

            clearInterval(id)
          }

        
        
      },50)
       
        
       
    
      
    }
   
   
   
  }
  
  

   filterCitiesAndStreets(filter:string,arr:any[])
  {
    let filtered=[]
    for (let index = 0; index < arr.length; index++) {
      if(arr[index].name.toLowerCase().includes(filter.toLowerCase()))
      {
        filtered.push(arr[index])
      }
    }
    return filtered
  }
  
  

 

  CancelClick() {
    this.dialogRef.close();
  }
  
}
