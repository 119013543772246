<h1 mat-dialog-title *ngIf="!this.input.edit">Add New Service</h1>
<h1 mat-dialog-title *ngIf="this.input.edit">Modify Service</h1>

<div mat-dialog-content>
   
    <form [formGroup]="form"  class="grid" #documentEditForm="ngForm" (ngSubmit)="this.submit()">

    
            <mat-form-field appearance="standard"  class="ontSerialNum" >
              <mat-select  #citySelector disableOptionCentering  placeholder="Ont" [(ngModel)]="this.selectedOntSn" [required]="true" formControlName="ontSerialNum">

                    <mat-select-trigger>
                      <span *ngIf="this.selectedOntSn">{{this.selectedOntSn.toUpperCase()}}</span>
                    </mat-select-trigger>
                    <mat-option>
                      <ngx-mat-select-search  placeholderLabel="Find ont..." #searchOnt></ngx-mat-select-search>
                    </mat-option>
                    <mat-option style="text-align: center;">- Choose -</mat-option>
                    <mat-option *ngFor="let ont of this.filterOnts(searchOnt.value,this.onts)" [value]="ont.sn">
                      <span>{{ont.sn.toUpperCase()}}</span>
                    </mat-option>
                    
                  </mat-select>
            </mat-form-field>
            

            <div class="serviceType" >
                <mat-form-field >
                    <mat-select  #citySelector disableOptionCentering  placeholder="Service Type" [required]="true" formControlName="serviceTypeId">

                      <mat-select-trigger>
                        {{this.getServiceTypeString()}}
                      </mat-select-trigger>
                      <mat-option>
                        <ngx-mat-select-search  placeholderLabel="Find service type..." #search></ngx-mat-select-search>
                      </mat-option>
                      <mat-option style="text-align: center;">- Choose -</mat-option>
                      <mat-option *ngFor="let serviceType of this.filter(search.value,this.serviceTypes)" [value]="serviceType.serviceTypeId">
                        <span>{{serviceType.name}}</span>
                      </mat-option>
                      
                    </mat-select>
                  </mat-form-field>
            </div>

           
                <mat-checkbox  (change)="useBillingAddress($event.checked)" class="check" >Same as billing address</mat-checkbox>
            
            




       

            <div class="city" >
                <mat-form-field >
                    <mat-select (ngModelChange)="getStreets($event)" #citySelector disableOptionCentering  placeholder="City" [required]="true" formControlName="cityId">

                      <mat-select-trigger>
                         {{ this.getCityString() }}
                      </mat-select-trigger>
                      <mat-option>
                        <ngx-mat-select-search  placeholderLabel="Find city..." #search></ngx-mat-select-search>
                      </mat-option>
                      <mat-option style="text-align: center;">- Choose -</mat-option>
                      <mat-option *ngFor="let city of this.filter(search.value,this.cities)" [value]="city.cityId">
                        <span>{{city.name}}</span>
                      </mat-option>
                      
                    </mat-select>
                  </mat-form-field>
            </div>

        

            <div class="street" >
                <mat-form-field >
                    <mat-select  disableOptionCentering  placeholder="Street" [required]="true" formControlName="streetId">
                      
                      <mat-select-trigger>
                        {{ this.getStreetString()}}
                     </mat-select-trigger>
                      <mat-option>
                        <ngx-mat-select-search   placeholderLabel="Find street..." #streetSearch></ngx-mat-select-search>
                      </mat-option>
                      <mat-option style="text-align: center;">- Choose -</mat-option>
                      <mat-option style="text-align: center;" (click)="openStreetsDialog();">+</mat-option>

                      <mat-option *ngFor="let street of this.filter(streetSearch.value,this.streets)" [value]="street.streetId">
                        <span >{{street.name}}</span>
                       
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                
            </div>

            <mat-form-field   appearance="standard" class="houseNum"  >
              <mat-label>House Number</mat-label>
              <input style="width:50px" matInput [required]="true"  formControlName="houseNum">
             </mat-form-field>
          
             <mat-form-field   appearance="standard" class="partialNum"  >
                <mat-label>Zipcode</mat-label>
                <input style="width:50px" matInput [required]="true" formControlName="zipcode">
            </mat-form-field>

           <mat-form-field   appearance="standard" class="apartment"  >
                <mat-label>Apartment</mat-label>
                <input style="width:50px" matInput formControlName="apartment">
           </mat-form-field>


           

            <mat-form-field   appearance="standard" class="gpsLon"  >
                <mat-label>Longitude</mat-label>
                <input style="width:50px" matInput [required]="false" formControlName="gpsLongitude">
            </mat-form-field>

            <mat-form-field   appearance="standard" class="gpsLat"  >
                <mat-label>Latitude</mat-label>
                
                <input   type="number" style="width:50px" matInput [required]="false" formControlName="gpsLatitude">
            </mat-form-field>


          

           

          



           
      
            

       
        
           
        
       

    </form>
    
   

</div>
<div mat-dialog-actions >
  <button mat-raised-button color="primary" form="ngForm" [disabled]="!this.form.valid" cdkFocusInitial (click)="this.submit()">Ok</button>
  <button mat-raised-button color="accent" (click)="CancelClick()">Cancel</button>
</div>
