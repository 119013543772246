import { Role } from "@ip6m/types";

export interface IdTokenData {
  sub: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  roles?: Role[];
}
