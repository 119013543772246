import { Injectable } from '@angular/core';
import { ONT_t } from '../../pages/map/map.component';
import { MarkerService } from '../marker/marker.service';
import * as L from 'leaflet';
import { OntService } from '../ont/ont.service';
import { OntState } from '@ip6m/types';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  searchMarker;
  ontMarker;
  active:boolean=false;
  searchInput:string=""
  constructor(private markerService:MarkerService,private ontService:OntService) { }

  public toggleForm()
  {
    this.active=!this.active
  }
  public queryLocation(queryString: string): ONT_t[] {
    let keys = queryString.split(/[; ,—.]+/);
    let results: ONT_t[] = [];

    if (queryString != '') {
      for (let index = 0; index < this.markerService.onts.length; index++) {
        let hit = 0;
        for (let j = 0; j < keys.length; j++) {
          if (
            this.markerService.onts[index].name
              .toLowerCase()
              .includes(keys[j].toLocaleLowerCase()) ||
            this.markerService.onts[index].ontSn
              .toLowerCase()
              .includes(keys[j].toLowerCase()) ||
            this.markerService.onts[index].customer_id
              .toString()
              .toLowerCase()
              .includes(keys[j].toLowerCase()) ||
            this.markerService.onts[index].street
              .toString()
              .toLowerCase()
              .includes(keys[j].toLowerCase()) ||
            this.markerService.onts[index].houseNum
              .toString()
              .toLowerCase()
              .includes(keys[j].toLowerCase()) ||
            this.markerService.onts[index].city
              .toString()
              .toLowerCase()
              .includes(keys[j].toLowerCase()) ||
            this.markerService.onts[index].service_id
              .toString()
              .toLowerCase()
              .includes(keys[j].toLowerCase())
          ) {
            hit++;
          }
        }
        if (hit == keys.length) {
          results.push(this.markerService.onts[index]);
        }
      }
    }

    return results;
  }


  public jumpToResult(ont: ONT_t,map) {
    this.ontService.foundOnt=ont
    let clearBtn = <HTMLElement>document.getElementById('clearBtn');
    clearBtn.style.display = 'block';
    this.searchInput=ont.service_id +" "+ ont.name +" "+ ont.city +" "+ ont.street +" "+ ont.houseNum
    map.setView(new L.LatLng(ont.lat, ont.lon), 19);
    if (this.searchMarker) {
      map.removeLayer(this.searchMarker);
      map.removeLayer(this.ontMarker);
    }
    let color="grey";


    if (ont.status == OntState.Offline || ont.signal == null) color = 'grey';
    else
    {   //-5:-12GREEN -13:-27:YELLOW   -27:-infRED   inf:0 grey
     
        if (ont.signal >= 0) color = 'grey';
        else if (ont.signal < -5 && ont.signal >=-24) color = '#00ff00';
        else if (ont.signal <-24 && ont.signal >= -27) color = '#ffff00';
        else color = '#ff0000';
        
    }
    let fontcolor="black";
    if(color=="grey")fontcolor="white"
   
   

    this.ontMarker=
      L.marker([ont.lat, ont.lon], {
        icon: L.divIcon({
          html:
            "<div style='color:"+fontcolor+";position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);text-align:center;background:"+color+";width:35px;border-radius:25%'>" +
            ont.service_id +
            '</div>',
          iconAnchor: [0, 0],
          iconSize: new L.Point(30, 12),
        }),
      })
      this.ontMarker.on('mouseover', (ev) => {
      
            
            this.ontService.ont=this.ontService.foundOnt
            console.log(this.ontService.ont)
            this.ontService.isPopup=true;
            this.ontService.isPopupOpen=true
             
       
      
  
       
      });
  
      this.ontMarker.on('mouseout', (ev) => {
        
        this.ontService.isPopup=false;
        this.ontService.isPopupOpen=false
      });
    /*map.removeLayer( this.markerService.ontMarkers[this.markerService.ontMarkers.length-1])
    this.markerService.ontMarkers.splice(this.markerService.ontMarkers.length-1,1)
    this.ontService.foundOnt=ont
    this.markerService.addOnt(ont,true)
    this.markerService.ontMarkers[this.markerService.ontMarkers.length-1].addTo(map)*/
   
    this.ontMarker.addTo(map)

    var greenIcon = L.icon({
      iconUrl: '../../../assets/images/searchMarker.png',

      iconSize: [130, 130],
      iconAnchor: [49, 57],
    });

    this.searchMarker = L.marker(L.latLng(ont.lat, ont.lon), {
      icon: greenIcon,
    }).addTo(map);
    this.searchMarker.setZIndexOffset(-100);
    /*if(this.searchMarker)
          {
            this.map.removeLayer(this.searchMarker)
          }
          this.searchMarker=L.circle(L.latLng(this.onts[index].lat, this.onts[index].lon)).addTo(this.map)*/
  }
}
