<h1 mat-dialog-title *ngIf="!this.editMode.edit">Add New Customer</h1>
<h1 mat-dialog-title *ngIf="this.editMode.edit">Modify Customer</h1>
<div mat-dialog-content>
   
    <form [formGroup]="form"  class="grid" #documentEditForm="ngForm" (ngSubmit)="this.submit()">




        

           
               
            <mat-form-field appearance="standard"  class="name" >
                <mat-label>Name</mat-label>
                <input  (keyup.enter)="submit()" matInput  placeholder="Type name" [required]="true" formControlName="name">
            </mat-form-field>
            

            <div class="company" >
                <mat-checkbox   class="center" formControlName="company">Company</mat-checkbox>
            </div>
            



       

            <div class="city" >
                <mat-form-field >
                    <mat-select (ngModelChange)="getStreets($event)" #citySelector disableOptionCentering  placeholder="City" [required]="true" formControlName="cityId">

                      <mat-select-trigger>
                         {{ this.getCityString()}}
                      </mat-select-trigger>
                      <mat-option>
                        <ngx-mat-select-search  placeholderLabel="Find city..." #search></ngx-mat-select-search>
                      </mat-option>
                      <mat-option style="text-align: center;">- Choose -</mat-option>
                      <mat-option *ngFor="let city of this.filterCitiesAndStreets(search.value,this.cities)" [value]="city.cityId">
                        <span>{{city.name}}</span>
                      </mat-option>
                      
                    </mat-select>
                  </mat-form-field>

               
            </div>

        

            <div class="street" >
                <mat-form-field >
                    <mat-select  disableOptionCentering  placeholder="Street" [required]="true" formControlName="streetId">
                      
                      <mat-select-trigger>
                        {{ this.getStreetString()}}
                     </mat-select-trigger>
                      <mat-option>
                        <ngx-mat-select-search   placeholderLabel="Find street..." #streetSearch></ngx-mat-select-search>
                      </mat-option>
                      <mat-option style="text-align: center;">- Choose -</mat-option>
                      <mat-option style="text-align: center;" (click)="openStreetsDialog()">+</mat-option>
                      <mat-option *ngFor="let street of this.filterCitiesAndStreets(streetSearch.value,this.streets)" [value]="street.streetId">
                        <span >{{street.name}}</span>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                
            </div>

            <mat-form-field   appearance="standard" class="houseNum"  >
              <mat-label>House Number</mat-label>
              <input  (keyup.enter)="submit()" style="width:50px" matInput [required]="true"  formControlName="houseNum">
             </mat-form-field>
          
             <mat-form-field   appearance="standard" class="partialNum"  >
              <mat-label>Zipcode</mat-label>
              <input  (keyup.enter)="submit()" style="width:50px" matInput [required]="true" formControlName="zipcode">
          </mat-form-field>

           <mat-form-field   appearance="standard" class="apartment"  >
                <mat-label>Apartment</mat-label>
                <input  (keyup.enter)="submit()" style="width:50px" matInput formControlName="apartment">
           </mat-form-field>


           

    </form>
    


</div>
<div mat-dialog-actions >
  <button  type="submit" mat-raised-button color="primary" form="ngForm" [disabled]="!this.form.valid" cdkFocusInitial (click)="this.submit()">Ok</button>
  <button mat-raised-button color="accent" (click)="CancelClick()">Cancel</button>
</div>
