import { Component, OnInit } from '@angular/core';
import { ChartService } from '../../services/chart/chart.service';
import { ChartInterval } from '@ip6m/types';
let chart;
@Component({
  selector: 'ip6m-historic-chart',
  templateUrl: './historic-chart.component.html',
  styleUrls: ['./historic-chart.component.css'],
})
export class HistoricChartComponent implements OnInit {
  constructor(private chartService: ChartService) {}

  ngOnInit(): void {}

  disposeChart() {
    if (chart) chart.dispose();
  }

  geneateChart(data, interval: ChartInterval) {
    let dataTmp=[]
    //transform timestamp to match inverval
    for (let index = 0; index < data.length; index++) {
      data[index].time = this.chartService.intervalToChartLabel(data[index].time, interval);
      
    }
    for (let index = 0; index < data.length; index++) {
      if(data[index].signal)dataTmp.push(data[index])
    }
    chart = this.chartService.generateHistoricChart(data);
    
  }





  
  
}
