<mat-form-field id="form">
  <div id="formBackground"></div>
  <input
    matInput
    id="queryString"
    placeholder="SEARCH"
    [(ngModel)]="this.searchService.searchInput"
    type="text"
  />

  <br />
  <mat-list id="matlist">
    <mat-list-item *ngFor="let ont of this.results">
      <button
        mat-flat-button
        id="resultBtn"
        (click)="this.searchService.jumpToResult(ont,this.mapService.map)"
      >
        {{ ont.service_id }} {{ ont.name }} {{ ont.city }} {{ ont.street }}
        {{ ont.houseNum }}
      </button>
    </mat-list-item>
  </mat-list>

  <button mat-flat-button id="searchClose" (click)="this.closeResults()">
    <h4 id="searchCloseTxt">⟰</h4>
  </button>

  <button mat-flat-button id="clearBtn" (click)="this.clear()">✕</button>
</mat-form-field>
