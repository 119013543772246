import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigEntity } from '@ip6m/server/data-access-config';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  publicConfig = {} as ConfigEntity;
  isConfigReady = false;
  origUrl = '/';

  constructor(private http: HttpClient, private router: Router) {}

  initConfig() {
    this.http.get<ConfigEntity>("/api/v1/configuration")
      .subscribe(config => {
        this.publicConfig = config;
        this.isConfigReady = true;
        if(config.userAuthEnabled == false) {
          this.router.navigate([this.origUrl]);
        }
      }
    )
  }
}
