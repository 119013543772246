import { Component, OnInit} from '@angular/core';
import { CacheService } from './services/cache/cache.service';


@Component({
  selector: 'ip6m-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'ip6m-gpon-NX-frontend';

  constructor(private cacheService:CacheService){}

  ngOnInit()
  {
    this.cacheService.startLoading()
    console.log("v0.3")
  }
  
}
