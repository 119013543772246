import { EthernetDuplexMode, EthernetSpeed } from '..';
import { hwPortType, srvPortType, tagTransform, OntDownCause, OntState, EthState, CustomerDbType } from './enums';

export interface snmpBuffer {
    type: string;
    data: number[];
}
export interface OLT {
    name: string;
    ipaddress: string;
    'read community': string;
    'write community': string;
    type: string;
    datacollector: boolean;
    'cms database': string
    'gpsLatitude': number,
    'gpsLongitude': number
}
export interface Slot {
    hwSlotIndex: string,
    hwSlotOperStatus?: string,
    hwSlotPhySerialNum?: ArrayBuffer,
    hwSlotTypeName?: ArrayBuffer,
    portNum?: number,           // summa number of port in on subslots
    ports?: Port[]
}
export interface Port {
    id: number,
    slotId?: string,
    hwPortType?: hwPortType,
    hwPortDesc?: any
}
export interface ApiPort {
    id: string,
    type?: string;
    subslotId?: string;
    slotId?: string;
    oids?: Oid[];
}
export interface Oid {
    name?: string;
    oid: string;
    type?: string;
    value?: any;
}
export class Ont {
    oltId: number
    ifIndex: number
    ontId: number
    sn: string
    hwGponDeviceOntRegisterResult?: string
    hwGponDeviceOntRegisterTime?: string
    hwGponDeviceOntRegisterConflictedIfindex?: string
    hwGponDeviceOntRegisterConflictedOntIndex?: string
    hwGponDeviceOntRegisterOntVendorId?: string
    hwGponDeviceOntRegisterOntVersion?: string
    hwGponDeviceOntRegisterOntMainSoftVersion?: string
    hwGponDeviceOntRegisterOntEquipmentId?: string
    hwGponDeviceOntRegisterOntLoid?: string
    hwGponDeviceOntDespt?: any
    hwGponDeviceOntLineProfName?: any
    hwGponDeviceOntServiceProfName?: any
    customer?: CustomerData
    ddm?: OntOpticalDdmInfo
}

export class OntsBySerialNum {
    [ontSn: string]: Ont
}

export interface ServicePort {
    id: number,
    vlanId?: number,
    portType?: srvPortType,  // if paraType=port and Para4 first bit=0 => Eth,
                            // if paraType=gpon and Para4 first bit=0 => Gemport,
                            // if Para4 first bit=1 => Iphost
    portIndex?: number,      // gemport or eth port index
    userVlanId?: number,
    receiveTrafficIndex?: number,
    transmitTrafficIndex?: number,
    tagTransform?: tagTransform,
    download?: string,
    upload?: string         
}

export interface ServicePortList {
    [frame: number]: {
        [slot: number]: {
            [port: number]: {
                [ontId: number]: ServicePort[]
            }
        }    
    }
}

export interface OntParams {
    frame: number,
    slot: number,
    port: number,
    ontId: number
}

export interface IpTrafficParamTable {
    [name: number]: {
        hwIpTrafficName: string
    }
}

export interface Vlan {
    id: number,
    descr: string,
    type: string,
    ports: string
}

export interface Vlans {
    [id: number]: Vlan
}

export interface CustomerDBStatus {
  status: boolean,
  provider: string,
  title: string,
  description: string,
  customerNum: number,
  ontNum: number 
}

export interface GiganetCustomerData {
    szolg_id: string,
    ugyfel_id: string,
    ugyfel_nev: string,
    irsz: string,
    telepulesnev: string,
    varosresz: string,
    kozternev: string,
    kozterjelleg: string,
    hazszam: string,
    hazszam2: string,
    szolg_gps_latitude: number,
    szolg_gps_longitude: number,
    dist_box: string,
    gpon_serial: string
}
export interface CustomerData {
       serviceId: string,
       customerId: string,
       name: string,
       zipcode: string,
       city: string,
       street: string,
       houseNum: string,
       gpsLatitude: number,
       gpsLongitude: number,
       ontSn: string,
       networkNode: string
}

export interface CustomersBySerial {
    [sn: string]: CustomerData
}


export interface CustomerLists {
    [custDb: string]: CustomersBySerial
}

export class Charts {
    signalChart: string;
    trafficChart: string;
    packetsChart: string; 
}

export class RRDdata{
    time : Date;
    signal : number;
}

export class OntRealTimeData
{
    status: OntState;
    ethStatus: EthState;
    signal: OntOpticalDdmInfo;
    downCause: OntDownCause;
   
}
export interface OntInfo
{
    desc:string,
    type:string 
}

export class OntOpticalDdmInfo {
    OpticalDdmTemperature: number;
    OpticalDdmBiasCurrent: number
    OpticalDdmTxPower: number
    OpticalDdmRxPower: number
    OpticalDdmVoltage: number
    OpticalDdmOltRxOntPower: number
    OpticalDdmAniCATVRxPower: number
}

export class OntOpticalDdmInfosBySerial {
    [sn: string]: OntOpticalDdmInfo;
}

export class OntEthernetStatus {
    hwGponDeviceOntEthernetDuplexMode: EthernetDuplexMode
    hwGponDeviceOntEthernetSpeed: EthernetSpeed
    hwGponDeviceOntEthernetOnlineState: EthState
}

export interface CustomerRaw {
    customerId:string,
    company:boolean,
    name:string
}

export interface ServiceType{
    serviceTypeId:number
    name:string
}
export interface City{
    cityId:number,
    name:string,
   
}
export interface Street{
    streetId:number,
    name:string,
    
}
export class GponXProfiles {
    sp: string;
    lp: string;
}

export interface Role {
    id: number,
    name: string
}
  
export interface User {
    id: number,
    email: string,
    phone: string,
    firstName: string,
    lastName: string,
    isActive: boolean,
    roles: Role[]
}
export class PasswordData {
    uid: number;
    password: string;
}
