import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransformsService {

  constructor() { }
  public translateSignal(ddmSignal) {
    let signal=0;
    if (ddmSignal < 0) {
      signal = Math.floor(Math.abs(ddmSignal) / 100);
      signal += (Math.abs(ddmSignal) % 100) / 100;
      signal *= -1;
    }
    
    return parseFloat(signal.toFixed(2));
  }
  public decodeOntType(ont) {
    var str = new TextDecoder().decode(
      new Uint8Array(ont['hwXponOntInfoProductDescription'].data)
    );
    str = str.substring(9, 16);
    return str;
  }

  public getSlotPort(ifindex) {
    let k = ifindex - 4194304000;
    for (let i = 0; i < 22; i++) {
      for (let j = 0; j < 16; j++) {
        if (i * 8192 + j * 256 == k) {
          return [i, j];
        }
      }
    }
  }
}
