import { ValueAxis } from '@amcharts/amcharts4/charts';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { OntParams, srvPortType, tagTransform } from '@ip6m/types';
import { OltService } from '../../services/olt/olt.service';
import { OntService } from '../../services/ont/ont.service';

@Component({
  selector: 'ip6m-add-service-port',
  templateUrl: './add-service-port.component.html',
  styleUrls: ['./add-service-port.component.css']
})
export class AddServicePortComponent implements OnInit {

  @Input() slot$: number;
  @Input() port$: number;
  @Input() ontId$: number;

  private ontParams: OntParams;

  isActive$: Boolean = false;
  srvPortTypes = [
    {value: srvPortType['Gemport'], viewValue: 'Gpon'},
    {value: srvPortType['Eth'], viewValue: 'Eth Port'},
    {value: srvPortType['Iphost'], viewValue: 'Iphost'},
    {value: srvPortType['Undefined'], viewValue: 'Ont'}
  ];
  tagTransforms = [
    { value: tagTransform['default'], viewValue: 'default'},
    { value: tagTransform['translate'], viewValue: 'translate'},
    { value: tagTransform['transparent'], viewValue: 'transparent'}
  ]

  constructor(public oltService: OltService, private fb: FormBuilder, private ontService: OntService) {}

  addSrvPortForm = this.fb.group({
    srvPortTypeControl: [srvPortType['Gemport']],
    port: this.fb.group({
      gemPortControl: [{value: '', disabled: false}],
      lanPortControl: [{value: '', disabled: true}]
    }),
    bandWidthProfile: this.fb.group({
      upStreamProfileControl: [''],
      downStreamProfileControl: [''],
    }),
    networkSide: this.fb.group({
      vlanIdControl: ['', Validators.required],
      vlanTagTransformControl: [''],
    }),
    userSide: this.fb.group({
      userVlanTagControl: [65535],
      userVlanIdControl: ['']
    })
  });

  get isPortReqd() {
    if(this.addSrvPortForm.value['srvPortTypeControl'] < 3) {
      return true;
    }
    return false;
  }

  typeChanged() {
    if(this.addSrvPortForm.value['srvPortTypeControl'] == srvPortType['Gemport']) {
      this.addSrvPortForm.controls['port']['controls']['gemPortControl'].enable();
      this.addSrvPortForm.controls['port']['controls']['lanPortControl'].disable();        
    }
    if(this.addSrvPortForm.value['srvPortTypeControl'] == srvPortType['Eth']) {
      this.addSrvPortForm.controls['port']['controls']['gemPortControl'].disable();
      this.addSrvPortForm.controls['port']['controls']['lanPortControl'].enable();
    }
    if(this.addSrvPortForm.value['srvPortTypeControl'] == srvPortType['Iphost']) {
      this.addSrvPortForm.controls['port']['controls']['gemPortControl'].disable();
      this.addSrvPortForm.controls['port']['controls']['lanPortControl'].disable();
    }
  }

  onSubmit() {
    console.log(this.addSrvPortForm.value);
    const value = this.addSrvPortForm.value;
    let portIndex: number = 100;
    let userVlanId: number = 0;
    switch(value.srvPortTypeControl) {
      case srvPortType['Gemport']: portIndex = value.port.gemPortControl; break;
      case srvPortType['Eth']: portIndex = value.port.lanPortControl; break;
    }
    if(value.userSide.userVlanTagControl == 65535) {
      userVlanId = 65535;
    }
    else {
      userVlanId = value.userSide.userVlanIdControl;
    }


    this.ontService.addServicePort(this.oltService.oltid, this.ontParams, {
      id: 0,
      vlanId: value.networkSide.vlanIdControl,
      portType: value.srvPortTypeControl,   // if paraType=port and Para4 first bit=0 => Eth,
                                            // if paraType=gpon and Para4 first bit=0 => Gemport,
                                            // if Para4 first bit=1 => Iphost
      portIndex: portIndex,
      userVlanId: userVlanId,
      receiveTrafficIndex: value.bandWidthProfile.upStreamProfileControl,
      transmitTrafficIndex: value.bandWidthProfile.downStreamProfileControl,
      tagTransform: value.networkSide.vlanTagTransformControl,
    }).subscribe(response => {
      console.log(response);
    });
  }


  ngOnInit(): void {
    this.ontParams = {
      frame: 0,
      slot: this.slot$,
      port: this.port$,
      ontId: this.ontId$
    }
  }

  clickAddSrvPort() {
    this.isActive$ = true;
  }

  clickCancel() {
    this.isActive$ = false;
  }
}
