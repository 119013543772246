import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from '@ip6m/types';
import { AuthService } from '@ip6m/client/auth';
import { PersonEditDialogComponent } from '../../components/person-edit-dialog/person-edit-dialog.component';
import { PeopleService } from '../../services/people/people.service';

@Component({
  selector: 'szerviznet-people-page',
  templateUrl: './people-page.component.html',
  styleUrls: ['./people-page.component.css']
})
export class PeoplePageComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'email', 'roles', 'actions'];
  dataSource: User[] = [];

  constructor(
    private peopleService: PeopleService, 
    public authService: AuthService,
    public dialog: MatDialog) {}

  ngOnInit(): void {
    this.peopleService.getUsers().subscribe(data => {
      this.dataSource = data;
    })
  }

  openEditDialog(i: number): void {
    const data = {
      uid: this.dataSource[i].id,
      name: this.dataSource[i].firstName + ' ' + this.dataSource[i].lastName
    }
    const dialogRef = this.dialog.open(PersonEditDialogComponent, {
      data: data
    });
  }
}
