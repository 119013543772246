import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { PeopleService } from '../../services/people/people.service';


@Component({
  selector: 'szerviznet-person-edit-dialog',
  templateUrl: './person-edit-dialog.component.html',
  styleUrls: ['./person-edit-dialog.component.css']
})
export class PersonEditDialogComponent {

  password = "";
  hide = true;

  constructor(
    public dialogRef: MatDialogRef<PersonEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { uid: number, name: string},
    private peopleService: PeopleService
  ) {}

  toggleVisibility(): void {
    this.hide = !this.hide;
  }

  saveButton(): void {
    this.peopleService.savePassword({
      uid: this.data.uid, 
      password: this.password
    }).subscribe(result => {
      if(result) {
        this.dialogRef.close();
      }
    })
  }


}
