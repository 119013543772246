import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ClientFeatureConfigurationModule } from '../../../feature-configuration/src/lib/client-feature-configuration.module';

@NgModule({
  imports: [
    CommonModule, 
    ClientFeatureConfigurationModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
})
export class ClientAuthModule {}
