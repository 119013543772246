import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { Ip6mNaviComponent } from './components/ip6m-navi/ip6m-navi.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { OltTreeComponent } from './components/olt-tree/olt-tree.component';
import { MatTreeModule } from '@angular/material/tree';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { OltinfoComponent } from './components/oltinfo/oltinfo.component';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { OltlistComponent } from './components/oltlist/oltlist.component';
import { OltslotlistComponent } from './components/oltslotlist/oltslotlist.component';
import { OltpageComponent } from './pages/oltpage/oltpage.component';
import { FrameinfoComponent } from './components/frameinfo/frameinfo.component';
import { PortsComponent } from './components/ports/ports.component';
import { PortdialogComponent } from './components/portdialog/portdialog.component';
import { BufferPipe } from './pipes/buffer/buffer.pipe';
import { OntlistComponent } from './components/ontlist/ontlist.component';
import { TimestampbufferPipe } from './pipes/timestampbuffer/timestampbuffer.pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { ServiceportsComponent } from './components/serviceports/serviceports.component';
import { VlanidPipe } from './pipes/vlanid/vlanid.pipe';
import { TagtransformPipe } from './pipes/tagtransform/tagtransform.pipe';
import { SrvPortTypePipe } from './pipes/srvporttype/srv-port-type.pipe';
import { AddServicePortComponent } from './components/add-service-port/add-service-port.component';
import {  CustomersComponent } from './pages/customers/customers.component';
import { OntSummaryComponent } from './components/ontsummary/ontsummary.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MapComponent } from './pages/map/map.component';
import { AgmCoreModule } from '@agm/core';

import { OntpopupComponent } from './components/ontpopup/ontpopup.component';
import { OntComponent } from './pages/ont/ont.component';
import { MapService } from './services/map/map.service';
import { MatTabsModule } from '@angular/material/tabs';
import { ClientSharedModule } from '@ip6m/client/shared';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { SettingsComponent } from './components/settings/settings.component';
import { MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { SearchformComponent } from './components/searchform/searchform.component';
import { HistoricChartComponent } from './components/historic-chart/historic-chart.component';
import { DashboardOntListsComponent } from './components/dashboard-ontlists/dashboard-ontlists.component';
import { DashboardOltList } from './components/dashboard-oltlist/dashboard-oltlist.component';
import { ClientAuthModule } from '@ip6m/client/auth';
import { ClientFeatureConfigurationModule } from '@ip6m/client/feature-configuration';
import { OntpagedetailComponent } from './components/ontpagedetail/ontpagedetail.component';
import { OntdialogComponent } from './components/ontdialog/ontdialog.component';
import { AutofoundlistComponent } from './components/autofoundlist/autofoundlist.component';
import { WeakontlistComponent } from './components/weakontlist/weakontlist.component';
import { WrongCoordinateOntlistComponent } from './components/wrong-coordinate-ontlist/wrong-coordinate-ontlist.component';
import { OntConfigComponent } from './components/ont-config/ont-config.component';
import { AddCustomerDialogComponent } from './pages/customers/add-customer-dialog/add-customer-dialog.component';
import { AddStreetDialogComponent } from './pages/customers/add-street-dialog/add-street-dialog.component';
import { ServiceListComponent } from './pages/customers/service-list/service-list.component';
import { AddServiceDialogComponent } from './pages/customers/add-service-dialog/add-service-dialog.component';
import { PeoplePageComponent } from './pages/people/people-page.component';
import { PersonEditDialogComponent } from './components/person-edit-dialog/person-edit-dialog.component';
@NgModule({
  declarations: [
    AppComponent,
    Ip6mNaviComponent,
    PeoplePageComponent,
    OltTreeComponent,
    DashboardComponent,
    OltinfoComponent,
    OltlistComponent,
    OltslotlistComponent,
    OltpageComponent,
    FrameinfoComponent,
    PortsComponent,
    PortdialogComponent,
    BufferPipe,
    OntlistComponent,
    TimestampbufferPipe,
    ServiceportsComponent,
    VlanidPipe,
    TagtransformPipe,
    SrvPortTypePipe,
    AddServicePortComponent,
    AddServiceDialogComponent,
    CustomersComponent,
    MapComponent,
    OntComponent,
    OntSummaryComponent,
    OntpopupComponent,
    SearchformComponent,
    HistoricChartComponent,
    SettingsComponent,
    DashboardOntListsComponent,
    DashboardOltList,
    OntpagedetailComponent,
    OntdialogComponent,
    AutofoundlistComponent,
    WeakontlistComponent,
    WrongCoordinateOntlistComponent,
    OntConfigComponent,
    AddCustomerDialogComponent,
    AddStreetDialogComponent,
    ServiceListComponent,
    PersonEditDialogComponent
  ],
  imports: [
    ClientFeatureConfigurationModule,
    ClientAuthModule,
    BrowserModule,
    NgxMatSelectSearchModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatSliderModule,
    FormsModule,
    MatIconModule,
    LayoutModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTreeModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MatTooltipModule,
    MatExpansionModule,
    MatDividerModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatTableModule,
    AgmCoreModule.forRoot({
      apiKey: '',
    }),
    MatPaginatorModule,
    MatSortModule,
    ClientSharedModule,
    MatBottomSheetModule
  ],
  providers: [
    MapService, 
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, 
      useValue: {appearance: 'fill'}
    },
    {
      provide: 'API_BASE_URL', 
      useValue: "/api"
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}


