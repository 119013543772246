import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Ont, OntDownCause, OntState } from '@ip6m/types';
import { ONT_t } from '../../pages/map/map.component';
import { DatacollectorService } from '../datacollector/datacollector.service';
import { MapService } from '../map/map.service';
import { MarkerService } from '../marker/marker.service';
import { OntService } from '../ont/ont.service';
import { SearchService } from '../search/search.service';
let search;
@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  
  constructor(
    private router:Router,
    private searchService:SearchService,
    private mapService:MapService,
    private markerService:MarkerService,
    private ontService:OntService
    
    ) { }

  public jumpToWeakOnt(row)
  {  let ont:ONT_t;
    console.log(row)
    let f =setInterval(()=>{
    
      for (let index = 0; index < this.markerService.onts.length; index++) {
       if(row.ontSn==this.markerService.onts[index].ontSn)
       {
         ont =this.markerService.onts[index]
       }
       if(ont)
       {
         clearInterval(f);
         
       }
      
    }
    },200)

    this.router.navigateByUrl("/map")
         setTimeout(()=>{
             
             
             console.log(ont)
             this.searchService.jumpToResult(ont,this.mapService.map)
             
           
         
           
         },2000)
    
      
     
      
    
    
  
      
 
  
  }
  public openOnt(sn)
  { 
   
    this.ontService.isPopup=false;
    this.router.navigateByUrl("/ont/"+sn)
         

  
  }
}
