import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { ActivatedRoute } from '@angular/router';
import { OltlistService } from '../../services/oltlist/oltlist.service';
import { OLT } from '@ip6m/types';
import { HttpClient } from '@angular/common/http';
import { OltService } from '../../services/olt/olt.service';

@Component({
  selector: 'ip6m-oltpage',
  templateUrl: './oltpage.component.html',
  styleUrls: ['./oltpage.component.css']
})
export class OltpageComponent implements OnInit {
  oltid$: string;
  oltList$: OLT[] = this.oltlistService.oltList;

  /** Based on the screen size, switch from standard to one column per row */
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'Slots', cols: 2, rows: 3 },
          { title: 'System', cols: 1, rows: 1 },
          { title: 'Messages', cols: 1, rows: 2 },
          { title: 'Frame', cols: 1, rows: 2 }
        ];
      }

      return [
        { title: 'Slots', cols: 2, rows: 3 },
        { title: 'System', cols: 1, rows: 1 },
        { title: 'Messages', cols: 1, rows: 2 },
        { title: 'Frame', cols: 1, rows: 2 }
      ];
    })
  );

  constructor(private http:HttpClient, private breakpointObserver: BreakpointObserver, protected route: ActivatedRoute, public oltService: OltService, private oltlistService: OltlistService) {}

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.oltid$ = params.get('id');
    });
    this.oltService.setOltid(this.oltid$);
  }
}
