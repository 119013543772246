<button mat-icon-button tabindex="-1" class="close-x" [mat-dialog-close]>
    <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title>{{ data.name }}</h1>
<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label i18n>Password</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="password">
        <mat-icon matSuffix (click)="toggleVisibility()">{{ hide ? 'visibility' : 'visibility_off' }} </mat-icon>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="saveButton()" i18n>Save</button>
    <button mat-raised-button color="accent" [mat-dialog-close] i18n>Cancel</button>
</div>
