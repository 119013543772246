<h1 mat-dialog-title>Add New Street</h1>
<div mat-dialog-content>
   
    <form [formGroup]="form"  class="grid" #documentEditForm="ngForm" (ngSubmit)="this.submit()">




      <div class="city" >
        <mat-form-field >
            <mat-select  #citySelector disableOptionCentering  placeholder="City" [required]="true" formControlName="cityId">

              <mat-select-trigger>
                 {{this.getCityString(citySelector.value)}}
              </mat-select-trigger>
              <mat-option>
                <ngx-mat-select-search  placeholderLabel="Find city..." #search></ngx-mat-select-search>
              </mat-option>
              <mat-option style="text-align: center;">- Choose -</mat-option>
              <mat-option *ngFor="let city of this.filterCitiesAndStreets(search.value,this.data.cities)" [value]="city.cityId">
                <span>{{city.name}}</span>
              </mat-option>
              
            </mat-select>
          </mat-form-field>
    </div>

               
            <mat-form-field appearance="standard"  class="name" >
                <mat-label>Name</mat-label>
                <input  matInput #name placeholder="Type name" [required]="true" formControlName="name">
            </mat-form-field>



           
    </form>
   

   


</div>
<div mat-dialog-actions >
  <button mat-raised-button color="primary" form="ngForm" [disabled]="!this.form.valid" cdkFocusInitial (click)="this.submit()">Ok</button>
  <button mat-raised-button color="accent" (click)="CancelClick()">Cancel</button>
</div>
