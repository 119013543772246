export enum CustomerDbType {
    default = 'defaultClient',
    giganet = 'giganetClient'
}
export enum CustomerDbMicroservicePort {
    giganet = 3334,
    default = 3335
}
export enum CustomerDbApi{
    port=3336
}

export enum RowStatus {
    active = 1,
    createAndGo = 4,
    destroy = 6
}

export enum hwSlotOperStatus {
    online = 1,
    normal = 2,
    fault = 3,
    forbidden = 4,
    autofind = 5,
    config = 6,
    offline = 7,
    abnormal = 8,
    versionerr = 9,
    autoload = 10,
    outofservice = 11,
    graceful = 12,
    shutdown = 13,
    energysavingshutdown = 14,
    hightempshutdown = 15,
    manualshutdown = 16,
    mismatch = 17,
    acoffshutdown = 18
}

export enum hwPortType {
    feElcPort = 571,
    bitsPort = 575,
    gponOltPort = 579,
    tengeOptPort = 591
}

export enum hwPortOperStatus {
    normal = 1,
    fault = 2,
    localLoopback = 3,
    forbidden = 4,
    test = 5,
    remoteLoopback = 6,
    activing = 10,
    deactive = 11,
    block = 12,
    nolight = 13,
    innerLocalLoopback = 14,
    innerRemoteLoopback = 15,
    locked = 16,
    busy = 17,
    maintain = 18,
    standbynormal = 19,
    standbyfault = 20,
    adslloopbackafe = 21,
    adslloopbackhybrid = 22,
    rtuLocalLoopback = 23,
    rtuRemoteLoopback = 24,
    linkup = 25,
    linkdown = 26,
    powerDenied = 50
}

export enum hwPortAdminStatus {
    active = 1,
    deactive = 2,
    block = 3,
    unblock = 4,
    confirm = 5,
    delete = 6,
    reset = 7,
    localLoopback = 8,
    remoteLoopback = 9,
    stopLoopback = 11,
    innerLocalLoopback = 12,
    innerRemoteLoopback = 13,
    rtuLocalLoopback = 14,
    rtuRemoteLoopback = 15,
    utopiaLocalLoopback = 16,
    afeLocalLoopback = 17,
    hybirdLocalLoopback = 18,
    noLoopBackNoTest = 50,
    noLoopBackTest = 51,
    localLoopBackNoTest = 52,
    localLoopBackTest = 53,
    remoteLoopBackNoTest = 54,
    remoteLoopBackTest = 55,
    localLoopbackPTM = 56,
    eocLoopbackNoTest = 57,
    eocLoopbackTest = 58,
    prohibited = 59
}

export enum SrvMultiServiceType {
    byUserVlan = 1,
    byEncap = 2,
    by8021p = 3,
    byVlanEncap = 4,
    byDoubleVlan = 5,
    by8021pList= 6,
    singleService = 255
}

export enum srvPortParaType {
    Gpon = 4,
    Port = 14
}

export enum srvPortType {
    Eth = 0,
    Iphost = 1,
    Gemport = 2,
    Undefined = 100
}

export enum tagTransform {
    default = 0,
    transparent = 1,
    translate = 2
}

export enum VlanType {
    superVlan = 1,
    commonVlan = 2,
    smartVlan = 7,
    muxVlan = 8,
    multiVlan = 9
}


export enum OntDownCause {
    lossOfPower = "dying-gasp",
    lossOfSignal1= "LOSi/LOBi",
    lossOfSignal2 = "LOS",
    lossOfFrame = "LOFi"
}

export enum ChartInterval {
    Hourly = "Hourly",
    Daily = "Daily",
    Weekly = "Weekly",
    Monthly = "Monthly",
    Annual = "Annual"
    
}

export enum ChartType{
    Signal = "signal",
    Traffic = "traffic",
    Packet = "packet"
}

export enum OntState {
    Online = 0,
    Offline = 1
}

export enum EthState {
    linkup = 1,
    linkdown = 2,
    invalid = -1
}

export enum EthernetDuplexMode {
    half = 1,
    full = 2,
    autoneg = 3,
    autohalf = 4,
    autofull = 5,
    invalid = -1
}

export enum EthernetSpeed {
    speed10M = 1,
    speed100M  = 2,
    speed1000M = 3,
    autoneg = 4,
    autospeed10M =5,
    autospeed100M =6,
    autospeed1000M =7,
    invalid = -1
}

