import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Ont, OntOpticalDdmInfo, OntState } from '@ip6m/types';
import * as L from 'leaflet';
import { OntdialogComponent } from '../../components/ontdialog/ontdialog.component';


import { ONT_t } from '../../pages/map/map.component';
import { DatacollectorService } from '../datacollector/datacollector.service';
import { DdmService } from '../ddm/ddm.service';
import { OntService } from '../ont/ont.service';
@Injectable({
  providedIn: 'root'
})
export class MarkerService {
  public customerNums=[]
  ontMarkers = [];
  onts: ONT_t[] = [];
  ontsB: Ont[] = [];
  public ontsLoaded:boolean=false;

  constructor(private dialog:MatDialog ,private ontService:OntService,private dataCollectorService:DatacollectorService,private ddmService:DdmService) { }
  
  
  
  startUpdate()
  {
    let interval=300000/this.ontMarkers.length
    let index=0;
    setInterval(()=>{
      let color="grey";
      if (this.onts[index].status == OntState.Offline || this.onts[index].signal == null) color = 'grey';
      else
      {
       
        if (this.onts[index].signal >= 0) color = 'grey';
        else if (this.onts[index].signal < -5 && this.onts[index].signal >=-24) color = '#00ff00';
        else if (this.onts[index].signal <-24 && this.onts[index].signal >= -27) color = '#ffff00';
        else color = '#ff0000';
          
      }
      let fontcolor="black";
      if(color=="grey")fontcolor="white"



       
        this.ontMarkers[index].setIcon(L.divIcon({
          html:
            "<div style='color:"+fontcolor+";position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);text-align:center;background:"+color+";width:35px;border-radius:25%'>" +
            this.onts[index].service_id +
            '</div>',
          iconAnchor: [0, 0],
          iconSize: new L.Point(30, 12),
        }))
        index++;
        if(index==this.ontMarkers.length)index=0;
      
    },interval)
   
  }
 



  addOnt(ont: ONT_t,search:boolean) {
   
    let index = this.onts.length - 1;
    let color="grey";
    if (ont.status == OntState.Offline || ont.signal == null) color = 'grey';
    else
    {
     
      if (ont.signal >= 0) color = 'grey';
      else if (ont.signal < -5 && ont.signal >=-24) color = '#00ff00';
      else if (ont.signal <-24 && ont.signal >= -27) color = '#ffff00';
      else color = '#ff0000';
        
    }
    let fontcolor="black";
    if(color=="grey")fontcolor="white"
   
   

    this.ontMarkers.push(
      L.marker([ont.lat, ont.lon], {
        icon: L.divIcon({
          html:
            "<div style='color:"+fontcolor+";position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);text-align:center;background:"+color+";width:35px;border-radius:25%'>" +
            ont.service_id +
            '</div>',
          iconAnchor: [0, 0],
          iconSize: new L.Point(30, 12),
        }),
      })
    );

    this.ontMarkers[this.ontMarkers.length - 1].on('mouseover', (ev) => {
      
      for (let index = 0; index < this.ontMarkers.length; index++) {
        if (this.ontMarkers[index].getLatLng() == ev.latlng) {
          if(search)this.ontService.ont=this.ontService.foundOnt
          else this.ontService.ont=this.onts[index]
           this.ontService.isPopup=true;
           this.ontService.isPopupOpen=true
           
          break;
        }
      }

    

     
    });

    this.ontMarkers[this.ontMarkers.length - 1].on('mouseout', (ev) => {
      
      this.ontService.isPopup=false;
      this.ontService.isPopupOpen=false
    });

    this.ontMarkers[this.ontMarkers.length - 1].on('click', (event) => {
       setTimeout(()=>{
        this.ontService.isPopupOpen=true
       },300)
       
       let ont_t=this.onts[index]
      
       if(search)
       {
         for (let i = 0; i < this.onts.length; i++) {
           if(ont.ontSn == this.onts[i].ontSn)
           {
            ont_t=this.onts[i]
            break;
           }
           
         }
       }
       this.openDialog(ont_t)
       this.ontService.ont=ont_t

    });
  }


  public openDialog(input: ONT_t): void {
    this.ontService.ont=input;
    const dialogRef = this.dialog.open(OntdialogComponent, {
      
    });
  }





  





  
  
  
}
