import { Component,OnInit} from '@angular/core';




@Component({
  selector: 'ip6m-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {  
  constructor(
  
  ) {}

  
}
