<h1>{{ this.autoFoundOnts.length }} Unregistered ONT(s)</h1>

<div class="table-container">
  <table
    mat-table
    [dataSource]="autoFoundDataSource"
    matSort
    class="mat-elevation-z8"
  >
    <ng-container *ngFor="let col of autoFoundColumns" matColumnDef="{{ col }}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ col }}</th>
      <td mat-cell *matCellDef="let element">{{ element[col] }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="autoFoundColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: autoFoundColumns"></tr>
  </table>
</div>
