import { NgModule } from '@angular/core';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import { OltpageComponent } from './pages/oltpage/oltpage.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { MapComponent } from './pages/map/map.component';
import { AuthenticatedGuard } from '@ip6m/client/auth';
import { OntComponent } from './pages/ont/ont.component';
import { PeoplePageComponent } from './pages/people/people-page.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'ont/:sn',
    component: OntComponent,
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'customers',
    component: CustomersComponent,
    canActivate: [AuthenticatedGuard],
  },
  { path: 'map', component: MapComponent, canActivate: [AuthenticatedGuard] },
  {
    path: 'olt/:id',
    component: OltpageComponent,
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'people',
    component: PeoplePageComponent,
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@ip6m/client/feature-login-and-registration').then(
        (m) => m.ClientFeatureLoginAndRegistrationModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('@ip6m/client/feature-self-care').then(
        (m) => m.ClientFeatureSelfCareModule
      ),
    canActivate: [AuthenticatedGuard],
  },
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
