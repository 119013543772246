import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AuthService } from '@ip6m/client/auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const accessToken = this.authService.accessToken;

    if (accessToken) {
      request = request.clone({
        headers: request.headers.set('authorization', accessToken),
      });
    }

    return next.handle(request);
  }
}
