<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list *ngIf="authService.isLoggedIn">
      <a mat-list-item routerLink="/dashboard">Dashboard</a>
      <a mat-list-item routerLink="/customers">Customers</a>
      <a mat-list-item routerLink="/map">Map</a>
      <ip6m-oltlist></ip6m-oltlist>
      <a mat-list-item routerLink="/people">People</a>
      <a mat-list-item href="/api">API Doc</a>
    </mat-nav-list>

    <mat-nav-list *ngIf="!authService.isLoggedIn && authService.isUserAuthEnabled">
      <a mat-list-item routerLink="/auth/login">Login</a>
      <a mat-list-item routerLink="/auth/registration">Registration</a>
      <a mat-list-item routerLink="/auth/one-time-password">Forgot password</a>
    </mat-nav-list>

    <mat-nav-list *ngIf="authService.isLoggedIn && authService.isUserAuthEnabled" class="username">
      <a mat-list-item>Logged in as
        {{ authService.decodedIdToken.email }}
      </a>
      <a mat-list-item routerLink="/profile">Profile</a>
      <a mat-list-item (click)="logout()">Logout</a>
    </mat-nav-list>
  
  </mat-sidenav>

  <mat-sidenav-content>
    <!-- Add Content Here -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
