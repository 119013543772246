import { object } from '@amcharts/amcharts4/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ont, OntDownCause, OntOpticalDdmInfo, OntState } from '@ip6m/types';
import { ONT_t } from '../../pages/map/map.component';
import { DatacollectorService } from '../datacollector/datacollector.service';
import { MapService } from '../map/map.service';
import { MarkerService } from '../marker/marker.service';
import { OltlistService } from '../oltlist/oltlist.service';
import { TransformsService } from '../transforms/transforms.service';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private loaded: boolean = false;
  private noCustomer = 0;
  private ontsNoCustomer: Ont[] = [];

  constructor(
    private oltlistservice: OltlistService,
    private markerService: MarkerService,
    private datacollectorService: DatacollectorService,
    private transformService: TransformsService,
    private mapService: MapService,
    private http: HttpClient,
    private oltListService: OltlistService
  ) {}
  public startLoading() {
    var timeout;
    document.addEventListener('mousemove', (ev) => {
      clearTimeout(timeout);
      this.mapService.mouseStopped = false;
      timeout = setTimeout(() => {
        this.mapService.mouseStopped = true;
      }, 100);
    });

    if (!this.loaded) {
      let id = setInterval(() => {
        if (this.oltlistservice.oltList) {
          this.datacollectorService.getOnts().subscribe((value) => {
            this.http
              .get<OntOpticalDdmInfo[]>('/api/v1/datacollector/ddm')
              .subscribe((ddms) => {
                this.markerService.customerNums = Array(
                  this.oltlistservice.oltList.length
                ).fill(0);

                Object.values(value).forEach((c) => {
                  let ont = this.assembleOnt(c);

                  if (ont) {
                    if (ddms[ont.ontSn]) {
                      ont.signal = this.transformService.translateSignal(
                        ddms[ont.ontSn].OpticalDdmRxPower
                      );
                    }

                    this.markerService.onts.push(ont);
                    this.markerService.ontsB.push(c);
                    this.markerService.addOnt(ont, false);
                  }
                });
                console.log('ONTs with no customer data: ' + this.noCustomer);
                for (
                  let index = 0;
                  index < this.ontsNoCustomer.length;
                  index++
                ) {
                  console.log(
                    '     ' +
                      this.ontsNoCustomer[index].sn +
                      '   ' +
                      this.transformService.decodeOntType(
                        this.ontsNoCustomer[index]
                      ) +
                      '   ' +
                      this.oltListService.oltList[
                        this.ontsNoCustomer[index].oltId
                      ].ipaddress
                  );
                }

                this.markerService.ontsLoaded = true;

                setInterval(() => {
                  this.http
                    .get<OntOpticalDdmInfo[]>('/api/v1/datacollector/ddm')
                    .subscribe((ddms:OntOpticalDdmInfo[]) => {
                      for (
                        let index = 0;
                        index < this.markerService.onts.length;
                        index++
                      ) {
                        let signal=this.transformService.translateSignal(ddms[this.markerService.onts[index].ontSn].OpticalDdmRxPower)

                       /*if(signal!=this.markerService.onts[index].signal)
                        {
                           //TODO   //signal has changed
                        }*/
                        
                        this.markerService.onts[index].signal =signal
                          
                      }
                    });
                }, 300000);
              });
          });
          this.loaded = true;
          clearInterval(id);
        }
      }, 2000);
    }
  }

  private assembleOnt(ont: Ont): ONT_t {
    //iterate through ONTs object
    let slotPort = this.transformService.getSlotPort(ont['ifIndex']);

    if (ont.customer) {
      //keep track of how many ONTS we could pair a customer with

      let type = this.transformService.decodeOntType(ont);
      let signal = -9999;
      let lon, lat;
      if (ont.customer.gpsLatitude) lat = ont.customer.gpsLatitude;
      if (ont.customer.gpsLongitude) {
        lon = ont.customer.gpsLongitude;
        this.markerService.customerNums[ont.oltId]++;
      }

      let tmp: ONT_t = {
        lon: ont.customer.gpsLongitude,
        lat: ont.customer.gpsLatitude,
        customer_id: parseInt(ont.customer.customerId),
        ontSn: ont.customer.ontSn,
        signal: signal,
        status: OntState.Online,
        lastDownCause: OntDownCause.lossOfPower,
        city: ont.customer.city,
        street: ont.customer.street,
        houseNum: ont.customer.houseNum,
        name: ont.customer.name,
        oltName: this.oltlistservice.oltList[ont.oltId].name,
        oltSlot: slotPort[0],
        oltPort: slotPort[1],
        service_id: ont.customer.serviceId,
        ontType: type,
        ontImage: '../../../assets/images/' + type + '.png',
      };
      return tmp;
    } else {
      this.noCustomer++;
      this.ontsNoCustomer.push(ont);
    }
  }
}
