import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { City, CustomerData, CustomerDBStatus, CustomerDbType, CustomerLists, CustomerRaw, CustomersBySerial, ServiceType, Street } from '@ip6m/types';
import { Observable } from 'rxjs';
import { CustomerRow } from '../../pages/customers/customers.component';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  
  constructor(private http: HttpClient) { }

  getStatus(): Observable<CustomerDBStatus[]> {
    return this.http.get("/api/v1/customer/status") as Observable<CustomerDBStatus[]>;
  }

  getCustomersByDb(type): Observable<CustomersBySerial> {
    return this.http.get("/api/v1/customer/type/" + type) as Observable<CustomersBySerial>;
  }
  

  getCustomers() {
    return this.http.get("/customer-api/v1/customer") as Observable<CustomerRow[]>;
  }
  getCities()
  {
    return this.http.get("/customer-api/v1/location/cities") as Observable<City[]>;
  
  }

  getStreets(cityId)
  {
    return this.http.get("/customer-api/v1/location/streets/city/"+cityId) as Observable<Street[]>;
  
  }
  newCustomer(payload)
  {
    console.log(payload)
    return this.http.post("/customer-api/v1/customer",payload)as Observable<CustomerRow>
  }

  newCity(payload)
  {
    return this.http.post("/customer-api/v1/location/cities",payload)as Observable<City>
  }

  newStreet(payload)
  {
    return this.http.post("/customer-api/v1/location/streets",payload)as Observable<Street>
  }

 

  getServicesByCustomer(id)
  {
    return this.http.get("/customer-api/v1/service/customer/"+id) as Observable<CustomersBySerial>
  }

  getServiceTypes()
  {
    return this.http.get("/customer-api/v1/service/types") as Observable<ServiceType[]>
  }

  newService(payload,customerId)
  {
    return this.http.post("/customer-api/v1/service/customer/"+customerId,payload) as Observable<ServiceType[]>
  }
  deleteService(serviceId)
  {
    return this.http.delete("/customer-api/v1/service/"+serviceId) as Observable<ServiceType[]>
  }
 
  getStreetsByName(streetName)
  {
    return this.http.get("/customer-api/v1/location/streets/name/"+streetName) as Observable<Street>;
  
  }
  
  updateCustomer(payload,customerId)
  {
    return this.http.patch("/customer-api/v1/customer/"+customerId,payload) as Observable<any>;
  
  }

  updateService(payload,serviceId)
  {
    
    return this.http.patch("/customer-api/v1/service/"+serviceId,payload) as Observable<any>;
  
  }

 
 
}

