
<div class="container">
<mat-card class="detail">
<ip6m-ontpagedetail *ngIf="this.ontService.ont && this.ontService.ont.ontSn==sn"></ip6m-ontpagedetail>

<mat-spinner class="ontDetailSpinner" *ngIf="this.ontService.loadingCode==-1" [diameter]="40"></mat-spinner>

<h1 *ngIf="this.ontService.loadingCode==1">Cannot find ONT</h1>
<h1 *ngIf="this.ontService.loadingCode==2">No customer data for this ONT</h1>
</mat-card>
<mat-card class="config">
    <ip6m-ont-config></ip6m-ont-config>
</mat-card>
</div>



