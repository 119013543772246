<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef class="right"> Id </th>
      <td mat-cell *matCellDef="let element" class="right"> {{ element.id }} </td>
    </ng-container>
  
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{ element.firstName }} {{ element.lastName }} </td>
    </ng-container>
  
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{ element.email }} </td>
    </ng-container>

    <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef> Roles </th>
      <td mat-cell *matCellDef="let element">
        <span *ngFor="let role of element.roles; let last = last">
          <span>{{ role.name }}</span>
          <span *ngIf="!last">, </span>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th> 
      <td mat-cell *matCellDef="let element; let i = index">
        <span *ngIf="authService.isAdmin">
          <button mat-button (click)="openEditDialog(i)">
            <mat-icon color="primary">manage_accounts</mat-icon>
          </button>
        </span>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
  </table>