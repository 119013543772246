import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MapService } from '../../services/map/map.service';

@Component({
  selector: 'ip6m-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  public  name:string;
  constructor(private bottomSheetRef: MatBottomSheetRef<SettingsComponent>,public mapservice:MapService) {}

  ngOnInit(): void {
    
  }

}
