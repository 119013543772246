import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ont, OntsBySerialNum } from '@ip6m/types';

@Injectable({
  providedIn: 'root'
})
export class DatacollectorService {

  constructor(private http:HttpClient) { }

  getOnt(ontSn)
  {
    return this.http.get<Ont>('/api/v1/datacollector/ont/'+ontSn);
  }
  getOnts()
  {
    return this.http.get<OntsBySerialNum>('/api/v1/datacollector/ont');
    
  }
}
