import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GponXProfiles, Ont, OntParams, OntRealTimeData, OntsBySerialNum, ServicePort } from '@ip6m/types';
import { Observable } from 'rxjs';
import { ONT_t } from '../../pages/map/map.component';
import { DatacollectorService } from '../datacollector/datacollector.service';
import { MarkerService } from '../marker/marker.service';
import { MessageService } from '../message/message.service';
import { OltlistService } from '../oltlist/oltlist.service';
import { RealTimeDataService } from '../realtime/real-time-data.service';
import { TransformsService } from '../transforms/transforms.service';


@Injectable({
  providedIn: 'root'
})
export class OntService {
  public ont:ONT_t;
  public ontg:Ont
  public isPopup:boolean=false;
  public isPopupOpen:boolean=false;
  public foundOnt:ONT_t
  public updater=[];
  public loadingCode:number=-1
  constructor(private http: HttpClient, 
    private transformService:TransformsService,
    private realtimeService: RealTimeDataService,
    private oltListService: OltlistService,
    private dataCollectorService: DatacollectorService) { }

  getAutoFoundOnts (oltId: number, slotId: number, portId: number) {
    return this.http.get<Ont[]>("/api/v1/olt/"+oltId+"/gpon/ont/autofound/" + this.ifIndex(slotId, portId));
  }

  getOnts (oltId: number, slotId: number, portId: number) {
    return this.http.get<OntsBySerialNum>("/api/v1/olt/"+oltId+"/gpon/ont/" + this.ifIndex(slotId, portId));
  }

  activateOnt (oltId: number, ont: Ont, profiles: GponXProfiles = null) {
    return this.http.post("/api/v1/olt/" + oltId + "/gpon/ont/activate/" + ont.ifIndex + "/" + ont.sn, profiles);
  }

  deactivateOnt (oltId: number, ont: Ont) {
    console.log(ont);
    return this.http.get("/api/v1/olt/" + oltId + "/gpon/ont/deactivate/" + ont.ifIndex + "/" + ont.ontId);
  }

  getOntServicePorts (oltId: number, oP: OntParams) {
    const url = "/api/v1/olt/" + oltId + "/serviceport/" + oP.frame + "/" + oP.slot + "/" + oP.port + "/" + oP.ontId;
    return this.http.get(url);
  }

  addServicePort (oltId: number, oP: OntParams, sp: ServicePort) {
    const url = "/api/v1/olt/" + oltId + "/serviceport/" + oP.frame + "/" + oP.slot + "/" + oP.port + "/" + oP.ontId;
    return this.http.post(url, sp);
  }

  deleteServicePort (oltId: number, oP: OntParams, i: number): Observable<Object> {
    const url:string = "/api/v1/olt/" + oltId + "/serviceport/" + oP.frame + "/" + oP.slot + "/" + oP.port + "/" + oP.ontId + "/" + i;
    return this.http.delete(url);
  }

  private ifIndex(slotid: number, portid: number) {
    return 4194304000 + 8192 * slotid + 256 * portid;
  }

  public getOnt(sn) {
   
    this.loadingCode=-1  //STILL LOADING
    this.ont=undefined
    let ont_t: ONT_t;
    this.dataCollectorService.getOnt(sn).subscribe((ont: Ont) => {
      this.ontg=ont
     
      this.realtimeService
        .loadRealTime(sn)
        .subscribe((data: OntRealTimeData) => {
          
         if(ont.customer)
         {
          
          let type = this.transformService.decodeOntType(ont);
          let slotPort = this.transformService.getSlotPort(ont.ifIndex);
          ont_t = {
            ontSn: sn,
            city: ont.customer.city,
            customer_id: parseInt(ont.customer.customerId),
            houseNum: ont.customer.houseNum,
            lastDownCause: data.downCause,
            oltName: this.oltListService.oltList[ont.oltId].name,
            oltSlot: slotPort[0],
            oltPort: slotPort[1],
            ontType: type,
            ontImage: '../../../assets/images/' + type + '.png',
            service_id: ont.customer.serviceId,
            status: data.status,
            signal: this.transformService.translateSignal(
              ont.ddm.OpticalDdmRxPower
            ),
            lat: ont.customer.gpsLatitude,
            lon: ont.customer.gpsLongitude,
            street: ont.customer.street,
            name: ont.customer.name,
          };
          
          this.ont=ont_t
          this.loadingCode=0 //SUCCESSFULLY LOADED ONT DATA
        }
        else
        {
          this.loadingCode=2  //COULDNT FIND CUSTOMER DATA FOR ONT
         
          
        }
         
        });
    },
    err=>{
      this.loadingCode=1  //COULDNT FIND ONT
    });
  }
}
