<h1 style="padding-top: 20px">{{ this.weakOnts.length }} Weak ONT(s)</h1>
<div class="table-container">
  <table
    mat-table
    [dataSource]="weakOntDataSource"
    matSort
    class="mat-elevation-z8"
  >
    <ng-container *ngFor="let col of weakOntColumns" matColumnDef="{{ col }}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ col }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element[col] }}
        <button
          *ngIf="col == ' '"
          mat-button
          (click)="this.navigationService.jumpToWeakOnt(element)"
        >
          <mat-icon> map</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="weakOntColumns"></tr>
    <tr
      class="row"
      mat-row
      *matRowDef="let row; columns: weakOntColumns"
      (click)="this.navigationService.openOnt(row.ontSn)"
    ></tr>
  </table>
</div>
