import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OntOpticalDdmInfo } from '@ip6m/types';

export interface DDMInfo
{
  signal:number;
  signalColor:string;

}

@Injectable({
  providedIn: 'root'
})
export class DdmService {

  constructor(private http:HttpClient) { }

  getDDMInfo(ontSn)
  {
    return this.http.get<OntOpticalDdmInfo>('/api/v1/datacollector/' + ontSn + '/ddm');
    
  }
  getAllDDMInfos()
  {
    return this.http.get<OntOpticalDdmInfo[]>("/api/v1/datacollector/ddm");
  }
}
