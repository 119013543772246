import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PasswordData, User } from '@ip6m/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PeopleService {

  constructor(private http: HttpClient) {}

  getUsers(): Observable<User[]> {
    return this.http.get('/api/v1/people') as Observable<User[]>;
  }

  savePassword(data: PasswordData): Observable<boolean> {
    console.log(data);
    return this.http.post('/api/v1/setpassword', data) as Observable<boolean>;
  }
}
