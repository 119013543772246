import { Component, Input, OnInit } from '@angular/core';
import { OntParams, ServicePort } from '@ip6m/types';
import { OltService } from '../../services/olt/olt.service';
import { OntService } from '../../services/ont/ont.service';

@Component({
  selector: 'ip6m-serviceports',
  templateUrl: './serviceports.component.html',
  styleUrls: ['./serviceports.component.css']
})
export class ServiceportsComponent implements OnInit {

  @Input() slot$: number;
  @Input() port$: number;
  @Input() ontId$: number;

  private ontParams: OntParams;

  servicePorts$: ServicePort[] = [];

  constructor(private oltService: OltService, private ontService: OntService) { }

  ngOnInit(): void {
    this.ontParams = {
      frame: 0,
      slot: this.slot$,
      port: this.port$,
      ontId: this.ontId$
    }
    this.ontService.getOntServicePorts(this.oltService.oltid, this.ontParams).subscribe(value => {
      for(let key in value) {
        this.servicePorts$.push(value[key]);
      }
      
      this.servicePorts$.forEach(srvPort => {
        if(srvPort.receiveTrafficIndex > 0 && srvPort.receiveTrafficIndex < 4294967294) {
          srvPort.download = this.oltService.ipTrafficParamTable[srvPort.receiveTrafficIndex].hwIpTrafficName;
        }
        if(srvPort.transmitTrafficIndex > 0 && srvPort.transmitTrafficIndex < 4294967294) {
          srvPort.upload = this.oltService.ipTrafficParamTable[srvPort.transmitTrafficIndex].hwIpTrafficName;
        }        
      });
    });
  }

  clickDeleteSrvPort(i: number) {
    const res = this.ontService.deleteServicePort(this.oltService.oltid, this.ontParams, i).subscribe(value =>{
      console.log(value);
      this.servicePorts$.splice(i);  // We have removed this item.
    })
  }
}
