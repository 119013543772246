import { Component, OnInit } from '@angular/core';
import { OLT } from '@ip6m/types';
import { OltlistService } from '../../services/oltlist/oltlist.service';

@Component({
  selector: 'ip6m-oltlist',
  templateUrl: './oltlist.component.html',
  styleUrls: ['./oltlist.component.css']
})
export class OltlistComponent implements OnInit {
  oltList$: OLT[] = this.oltlistService.oltList;

  constructor(private oltlistService: OltlistService) { }

  ngOnInit(): void {}
}
