import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as L from 'leaflet';
@Injectable({
  providedIn: 'root'
})
export class LayerService {
  public satelite: boolean = false;
  public wireActive:boolean=true;
  public wireArray=[]
  constructor(private http:HttpClient) { }
  satelitelayer = L.tileLayer(
    'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}?access_token={accessToken}',
    {
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken:
        'pk.eyJ1IjoidGlzemE0NDMiLCJhIjoiY2tyYzk2aXJ0MWR4dTJ3cDhsOXpxOHg3YSJ9.z5DvpMIhudTpv8qyxDd7Wg',
    }
  );
  cartodbLabels = L.tileLayer(
    'http://{s}.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}.png',
    {}
  );

  simplelayer = L.tileLayer(
    'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
    {
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 17,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken:
        'pk.eyJ1IjoidGlzemE0NDMiLCJhIjoiY2tyYzk2aXJ0MWR4dTJ3cDhsOXpxOHg3YSJ9.z5DvpMIhudTpv8qyxDd7Wg',
    }
  );

  toggleWire(map)
  {
    this.wireActive=!this.wireActive
    if(!this.wireActive)
    {
      for (let index = 0; index < this.wireArray.length; index++) {
        map.removeLayer(this.wireArray[index])
        
      }
    }
    else
    {
      for (let index = 0; index < this.wireArray.length; index++) {
        map.addLayer(this.wireArray[index])
        
      }
    }
  }
  loadGeoJson(map) {
    this.http
      .get('assets/Giganet_lefedo_nyomvonal_leges.geojson')
      .subscribe((json: any) => {
         json;
        this.wireArray.push(L.geoJSON(json).addTo(map));
      });

    this.http
      .get('assets/Giganet_gerinc_nyomvonal_leges.geojson')
      .subscribe((json: any) => {
        
        this.wireArray.push(L.geoJSON(json, {
          style: function (feature) {
            return { color: 'blue' };
          },
        }).addTo(map));
      });

    this.http
      .get('assets/Giganet_gerinc_nyomvonal_foldes.geojson')
      .subscribe((json: any) => {
        
        this.wireArray.push(L.geoJSON(json, {
          style: function (feature) {
            return { color: 'blue' };
          },
        }).addTo(map));
      });

    this.http
      .get('assets/Giganet_lefedo_nyomvonal_foldes.geojson')
      .subscribe((json: any) => {
       
        this.wireArray.push(L.geoJSON(json, {
          style: function (feature) {
            return { color: '#875100' };
          },
        }).addTo(map));
      });
  }
  
  changeMap(map) {
    var searchCollapse = <HTMLInputElement>document.getElementById('searchCollapse');
    
    var img = <HTMLInputElement>document.getElementById('sateliteImage');
    if (this.satelite) {
      searchCollapse.style.color="black";
      img.style.filter = 'invert(0)';
      this.simplelayer.addTo(map);
     
      map.removeLayer(this.satelitelayer);
      map.removeLayer(this.cartodbLabels);

      this.satelite = !this.satelite;
    } else {
      searchCollapse.style.color="white";
      img.style.filter = 'invert(1)';
      this.satelitelayer.addTo(map);
      this.cartodbLabels.addTo(map);
      map.removeLayer(this.simplelayer);
      this.satelite = !this.satelite;
    }
  }

}
