<mat-card style="padding: 20px">
 


<ip6m-autofoundlist></ip6m-autofoundlist>
<ip6m-weakontlist></ip6m-weakontlist>
<ip6m-wrong-coordiante-ontlist></ip6m-wrong-coordiante-ontlist>




  
</mat-card>
