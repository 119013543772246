import { Component, OnInit } from '@angular/core';
import { ONT_t } from '../../pages/map/map.component';
import { MapService } from '../../services/map/map.service';
import { MarkerService } from '../../services/marker/marker.service';
import { SearchService } from '../../services/search/search.service';

@Component({
  selector: 'ip6m-searchform',
  templateUrl: './searchform.component.html',
  styleUrls: ['./searchform.component.css'],
})
export class SearchformComponent implements OnInit {
  public results: ONT_t[] = [];
  public input:string=""
  constructor(private mapService: MapService,public searchService:SearchService,private markerService:MarkerService) {}

  ngOnInit(): void {
    var input = <HTMLInputElement>document.getElementById('queryString');
    input.addEventListener('keyup', (event) => {
      event.preventDefault();
      this.results = [];
      let clearBtn = <HTMLElement>document.getElementById('clearBtn');
      if (this.input == '') {
        clearBtn.style.display = 'none';
      } else {
        clearBtn.style.display = 'block';
      }
      this.results = this.searchService.queryLocation(input.value);
    });
  }

  clear() {
    this.results = [];
    this.searchService.searchInput=""
    this.input=""
    document.getElementById('clearBtn').style.display = 'none';
    this.mapService.map.removeLayer(this.searchService.ontMarker)
    this.mapService.map.removeLayer(this.searchService.searchMarker);
    this.searchService.searchMarker = null;
  }
  
  closeResults() {
    
    
    var list = <HTMLElement>document.getElementById('matlist');
    var btn = <HTMLElement>document.getElementById('searchCloseTxt');
    if (btn.innerHTML == '⟰') {
      list.style.maxHeight = '0px';
      btn.innerHTML = '⟱';
    } else {
      list.style.maxHeight = '300px';

      btn.innerHTML = '⟰';
    }
  }
}
