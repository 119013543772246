import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { City, CustomerDBStatus, CustomerDbType } from '@ip6m/types';
import { CustomerService } from '../../services/customer/customer.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { AddCustomerDialogComponent } from './add-customer-dialog/add-customer-dialog.component';
import {  CustomerData, CustomerLists, CustomerRaw, CustomersBySerial, ServiceType, Street } from '@ip6m/types';
import { DataSource } from '@amcharts/amcharts4/core';


export interface CustomerRow {
  position: number,
  customerId: string,
  name: string,
  company:boolean,
  city:string,
  street:string,
  houseNum:string,
  zipcode:string,
  apartment:string,
  isExpanded:boolean;
  
 
 
}
export interface GiganetCustomerRow {
  position: number,
  customerId: string,
  name: string,
  zipcode: string,
  city: string,
  street: string,
  house: string,
  ontSn: string,
  service:string,
}
let services:any[]=[]
let tableData: CustomerRow[] = []
let giganetTableData:GiganetCustomerRow[]=[]
let databases:CustomerDBStatus[]=[]
let servicesLoaded:boolean=false;
let dataLoaded: boolean = false;
@Component({
  selector: 'ip6m-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '100%' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ], 
})
export class CustomersComponent implements AfterViewInit {
  displayedColumns: string[] = [

     'position',
     'customerId',
     'name', 
     'company', 
     'city',
     'street',
     'houseNum',
     'zipcode',
     'apartment'
    ];

    giganetColumns: string[] = [

  
      'customerId',
      'name',
      'zipcode',
      'city',
      'street',
      'house',
      'ontSn',
      'service'
     
     ];



 
  dataSource: MatTableDataSource<CustomerRow>;

  services:any[]=[]
  customers:CustomerRow[]
 

 
  giganetDataSource:MatTableDataSource<GiganetCustomerRow>;
  databases$: CustomerDBStatus[];

  @ViewChild('MatPaginator1',{read: MatPaginator}) MatPaginator1: MatPaginator;
  @ViewChild('MatPaginator2',{read: MatPaginator}) MatPaginator2: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;



  constructor(private customerService:CustomerService,
    private dialog:MatDialog,
    public navigationService:NavigationService
    ) {
    this.dataSource = new MatTableDataSource(this.customers);
  }

 
  ngAfterViewInit() {

    if(!dataLoaded)
    { 
    
        
        this.customerService.getStatus().subscribe(data=>{
          this.databases$ = data 
          databases=this.databases$
          let local=false;
          let giganet=false;
          for (let index = 0; index < this.databases$.length; index++) {
            if(this.databases$[index].title=='Local Users')local=true
            if(this.databases$[index].title=='Giganet')giganet=true
            
            
            
          }
          if(giganet)this.loadGiganetServices()
          if(local)this.loadLocalData()


        });


        

       
   }
   else{
     this.databases$=databases
     this.customers=tableData
     this.dataSource = new MatTableDataSource(this.customers);
    
     this.services=services

     this.giganetDataSource = new MatTableDataSource(giganetTableData);
     
     setTimeout(() => {
      this.giganetDataSource.sort = this.sort;
      this.giganetDataSource.paginator=this.MatPaginator2
      this.dataSource.paginator = this.MatPaginator1;
      this.dataSource.sort = this.sort;
    }, 500);
    
   }
   
   
   
   

    
  }

  loadLocalData()
  {
    this.customerService.getCustomers().subscribe((customers)=>{
      this.customers=customers
    
    
      for (let index = 0; index < this.customers.length; index++) {
        this.customers[index].position=index+1
        this.customers[index].isExpanded=false
      }
      tableData=customers;
      this.dataSource = new MatTableDataSource(this.customers);
      this.dataSource.paginator = this.MatPaginator1;
      this.dataSource.sort = this.sort;
      dataLoaded=true;

      let counter=0;
  for (let index = 0; index < this.customers.length; index++) {
    this.customerService.getServicesByCustomer(this.customers[index].customerId).subscribe((resp)=>{
      Object.values(resp).forEach((service)=>{
        this.services.push(service)
      })
      counter++;
      
    })

    
    }

    let id =setInterval(()=>{
    if(counter==this.customers.length)
    {
        services=this.services
        servicesLoaded=true;
        clearInterval(id)
    }
  })


      

    })
  }

  loadGiganetServices()
  {
    this.customerService.getCustomersByDb("giganet").subscribe((data:CustomersBySerial)=>{
      let f:CustomerData;
      let i = 0;
      Object.keys(data).forEach((key)=>{
        i++;
        giganetTableData.push({
          position: i,
          customerId: data[key]['customerId'],
          name: data[key]['name'],
          zipcode: data[key]['zipcode'],
          city: data[key]['city'],
          street: data[key]['street'],
          house: data[key]['houseNum'],
          ontSn: data[key]['ontSn'],
          service: data[key]['serviceId']
      })
     
    
      
    })
      this.giganetDataSource = new MatTableDataSource(giganetTableData);
      this.giganetDataSource.paginator=this.MatPaginator2
     
     
    
  })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    this.giganetDataSource.filter = filterValue.trim().toLowerCase();
    
    if (this.giganetDataSource.paginator) {
      this.giganetDataSource.paginator.firstPage();
    }
  }

  editCustomer(customer)
  {
  
   
    const dialogRef = this.dialog.open(AddCustomerDialogComponent, {
      data:{
        edit:true,
        customer:customer
      }
    });
    dialogRef.afterClosed().subscribe(result=>{
      if(result && result.event=='submit')
      {
        console.log(result.data)
        let customer = this.customers.find(customer=> customer.customerId==result.data.customerId)
        this.customers[this.customers.indexOf(customer)].apartment=result.data.apartment
        this.customers[this.customers.indexOf(customer)].company=result.data.company
        this.customers[this.customers.indexOf(customer)].city=result.data.city
        this.customers[this.customers.indexOf(customer)].houseNum=result.data.houseNum
        this.customers[this.customers.indexOf(customer)].name=result.data.name
        this.customers[this.customers.indexOf(customer)].street=result.data.street
        this.customers[this.customers.indexOf(customer)].zipcode=result.data.zipcode
        tableData=this.customers
        
        
       
      }
    })
  }

  openAddCustomerDialog(): void {
    const dialogRef = this.dialog.open(AddCustomerDialogComponent, {
      data:{
        edit:false,
        customer:null
      }
    });
    dialogRef.afterClosed().subscribe(result=>{
      if(result && result.event=='submit')
      {
        result.data.position=tableData.length+1
        result.data.open=false;
        tableData.push(result.data)
        this.customers=tableData
        this.dataSource = new MatTableDataSource(this.customers);
        
       
      }
    })
  }
}


