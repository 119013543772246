import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { hwPortAdminStatus, hwPortOperStatus, Port } from '@ip6m/types';
import { OltService } from '../../services/olt/olt.service';
import { PortdialogComponent } from '../portdialog/portdialog.component';

@Component({
  selector: 'ip6m-ports',
  templateUrl: './ports.component.html',
  styleUrls: ['./ports.component.css']
})
export class PortsComponent implements OnInit {

  @Input() slotId$: string;

  constructor(public oltService: OltService, public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  portStatusClass(adminstatus: string , operstatus: string) {
    return "adminstatus-" + hwPortAdminStatus[adminstatus] + " operstatus-" + hwPortOperStatus[operstatus];
  }

  openDialog(port: Port): void {
    const dialogRef = this.dialog.open(PortdialogComponent, {
      data: {
        slot: this.slotId$,
        port: port
      }
    });
  }
}
