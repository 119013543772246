import { Component, OnInit } from '@angular/core';
import { OltService } from '../../services/olt/olt.service';
import { hwSlotOperStatus, Slot } from '@ip6m/types';
@Component({
  selector: 'ip6m-oltslotlist',
  templateUrl: './oltslotlist.component.html',
  styleUrls: ['./oltslotlist.component.css']
})
export class OltslotlistComponent implements OnInit {

  constructor(public oltService: OltService) { }

  opStat = function(slot: Slot) {
    // return hwSlotOperStatus[opstat];
    if(slot.hasOwnProperty('hwSlotOperStatus')) {
      return hwSlotOperStatus[slot.hwSlotOperStatus];
    } else {
      return "offline";
    }
  }

  ngOnInit(): void {}
}
