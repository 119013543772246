import { Component, OnInit, ViewChild } from '@angular/core';
import { OLT, Ont } from '@ip6m/types';
import { OltlistService } from '../../services/oltlist/oltlist.service';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { ChartService } from '../../services/chart/chart.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MapService } from '../../services/map/map.service';
import { ONT_t } from '../../pages/map/map.component';
import { NavigationService } from '../../services/navigation/navigation.service';
import { MarkerService } from '../../services/marker/marker.service';
import { MatDialog } from '@angular/material/dialog';
import { OntService } from '../../services/ont/ont.service';




@Component({
  selector: 'ip6m-dashboard-ontlists',
  templateUrl: './dashboard-ontlists.component.html',
  styleUrls: ['./dashboard-ontlists.component.css'],
})
export class DashboardOntListsComponent{
 

  
  

  constructor() {}
 

 
 


  
  
}
