import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { OLT } from '@ip6m/types';
import { OltlistService } from '../../services/oltlist/oltlist.service';
interface OLTDetail {
  oltName: string;
  oltColor: string;
  oltStatus: string;
}
let dataLoaded: boolean;
let oltServiceOlts: OLT[] = [];
@Component({
  selector: 'ip6m-dashboardoltlist',
  templateUrl: './dashboard-oltlist.component.html',
  styleUrls: ['./dashboard-oltlist.component.css'],
})
export class DashboardOltList implements OnInit {
  olts: OLTDetail[] = [];

  constructor(
    private http: HttpClient,
    public oltlistService: OltlistService
  ) {}

  ngOnInit() {


    let id = setInterval(() => {
      if(this.oltlistService.oltList)
      {
        oltServiceOlts = this.oltlistService.oltList;
        dataLoaded = true;
  
        for (let index = 0; index < oltServiceOlts.length; index++) {
          let color=""
          let text=""
          if(oltServiceOlts[index].datacollector)
          {
            color="#32a852"
            text="Online"
          }
          else
          {
            color="#a93232"
            text="Offline"
          }
          this.olts.push({
            oltName: oltServiceOlts[index].name,
            oltColor: color,
            oltStatus: text,
          });
        }
        clearInterval(id);
      }
   
    },2000);


  }
}
