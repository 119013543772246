import { Component, Inject, Input, OnDestroy, ViewChild } from '@angular/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { ONT_t } from '../../pages/map/map.component';
import {
  EthernetSpeed,
  EthState,
  Ont,
  OntDownCause,
  OntEthernetStatus,
  OntRealTimeData,
  OntState,
} from '@ip6m/types';
import { HistoricChartComponent } from '../historic-chart/historic-chart.component';
import { ChartService } from '../../services/chart/chart.service';
import { RealTimeDataService } from '../../services/realtime/real-time-data.service';
import { OntService } from '../../services/ont/ont.service';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { DatacollectorService } from '../../services/datacollector/datacollector.service';
import { MarkerService } from '../../services/marker/marker.service';
import { OltlistService } from '../../services/oltlist/oltlist.service';
import { Console } from 'console';
import { EthService } from '../../services/eth/eth.service';
import { TransformsService } from '../../services/transforms/transforms.service';
import { MapService } from '../../services/map/map.service';

@Component({
  selector: 'ip6m-ontpagedetail',
  templateUrl: './ontpagedetail.component.html',
  styleUrls: ['./ontpagedetail.component.css'],
})
export class OntpagedetailComponent implements OnDestroy {
  @Input()
  public ont: ONT_t = {
    customer_id: 0,
    service_id: '0',
    lon: 0,
    lat: 0,
    city: '-',
    street: '-',
    houseNum: '-',
    name: '-',
    ontSn: '-',
    signal: 0,
    status: OntState.Online,
    lastDownCause: OntDownCause.lossOfPower,
    oltName: '-',
    oltPort: 0,
    oltSlot: 0,
    ontType: '-',
    ontImage: '-',
  };
  public updater;

  public charts;
  public catvsignal;
  public signalColor = '';
  public ethColor = '';
  public realTime;
  public id;
  public ethSpeed = '-';
  public eth;
  public signal;
  public displayedSignal = 'N/A';
  public displayedCATV = 'N/A';
  public open: boolean = false;
  public updateInterval = 2;
  public interval = '';
  public chartTypes: string[] = [
    'Hourly',
    'Daily',
    'Weekly',
    'Monthly',
    'Annual',
  ];
  @ViewChild(HistoricChartComponent, { static: false })
  historicChart: HistoricChartComponent;
  constructor(
    private chartService: ChartService,
    private realtimeService: RealTimeDataService,
    public ontService: OntService,
    public transformService: TransformsService,
    private ethService: EthService,
    private mapService: MapService
  ) {}
  ngOnDestroy() {
    this.ontService.isPopupOpen=false;
    clearInterval(this.id);
  }
  ngAfterViewInit() {
    this.ontService.isPopupOpen=true;
    this.ont = this.ontService.ont;
    setTimeout(() => {
      if (this.mapService.mouseStopped || !this.ontService.isPopup) {

        if(this.ont==this.ontService.ont)
        {
          this.initData();
          this.startUpdates(this.updateInterval);
        }
      }
    }, 500);
  }

  private initData() {
    this.realtimeService
      .loadRealTime(this.ont.ontSn)
      .subscribe((value: OntRealTimeData) => {
        
        this.ethService
          .getEthData(this.ont.ontSn)
          .subscribe((ethdata: OntEthernetStatus) => {
            switch (ethdata.hwGponDeviceOntEthernetSpeed) {
              case 1:
                this.ethSpeed = 'Speed 10M';
                break;
              case 2:
                this.ethSpeed = 'Speed 100M';
                break;

              case 3:
                this.ethSpeed = 'Speed 1000M';
                break;
              case 4:
                this.ethSpeed = 'Autoneg';
                break;

              case 5:
                this.ethSpeed = 'Autospeed 10M';
                break;

              case 6:
                this.ethSpeed = 'Autospeed 100M';
                break;

              case 7:
                this.ethSpeed = 'Autospeed 1000M';
                break;
              case -1:
                this.ethSpeed = 'invalid';
                break;
            }
          });
        this.signal = 0;
        this.realTime = value;
        if (value['ethStatus'] == EthState['linkup']) {
          this.eth = 'UP';
          this.ethColor = '#00bb00';
        } else {
          this.eth = 'DOWN';
          this.ethColor = '#bb0000';
        }
        this.signal = this.transformService.translateSignal(
          value.signal.OpticalDdmRxPower
        );
        this.catvsignal = this.transformService.translateSignal(
          value.signal.OpticalDdmAniCATVRxPower
        );
        this.displayedCATV = this.catvsignal.toString();
        this.displayedSignal = this.signal.toString();
        if (!this.ontService.isPopup) {
          this.chartService.generateRealtimeChart();
        }
        //determine color assigned to signal level
        if (this.signal >= 0) this.signalColor = 'grey';
        else if (this.signal < -5 && this.signal >=-24) this.signalColor = '#00bb00';
        else if (this.signal <-24 && this.signal >= -27) this.signalColor = '#bbbb00';
        else this.signalColor = '#bb0000';
      });
  }

  private startUpdates(updateInterval) {
    var input = [];

    for (let index = 15; index >= 0; index--) {
      let date = new Date(Date.now() - index * 2000);
      let hours = date.getHours().toString();
      let minutes = date.getMinutes().toString();
      let seconds = date.getSeconds().toString();
      if (date.getHours() < 10) {
        hours = '0' + hours;
      }
      if (date.getMinutes() < 10) {
        minutes = '0' + minutes;
      }
      if (date.getSeconds() < 10) {
        seconds = '0' + seconds;
      }
      let dateStr = hours + ':' + minutes + ':' + seconds;

      input.push({
        date: dateStr,
        value: this.signal,
        color: this.signalColor,
      });
    }

    let id = setInterval(() => {
      if ((!this.ontService.isPopupOpen && !this.ontService.isPopup) ||
      (this.mapService.mouseStopped=true && !this.ontService.isPopupOpen)) {
        clearInterval(id);
      }
      if (this.ont.ontSn == this.ontService.ont.ontSn) {
        this.realtimeService
          .loadRealTime(this.ont.ontSn)
          .subscribe((value: OntRealTimeData) => {
            this.ethService
              .getEthData(this.ont.ontSn)
              .subscribe((ethdata: OntEthernetStatus) => {
                switch (ethdata.hwGponDeviceOntEthernetSpeed) {
                  case 1:
                    this.ethSpeed = 'Speed 10M';
                    break;
                  case 2:
                    this.ethSpeed = 'Speed 100M';
                    break;

                  case 3:
                    this.ethSpeed = 'Speed 1000M';
                    break;
                  case 4:
                    this.ethSpeed = 'Autoneg';
                    break;

                  case 5:
                    this.ethSpeed = 'Autospeed 10M';
                    break;

                  case 6:
                    this.ethSpeed = 'Autospeed 100M';
                    break;

                  case 7:
                    this.ethSpeed = 'Autospeed 1000M';
                    break;
                  case -1:
                    this.ethSpeed = 'invalid';
                    break;
                }
              });

            if (value['ethStatus'] == EthState['linkup']) {
              this.eth = 'UP';
              this.ethColor = '#00bb00';
            } else {
              this.eth = 'DOWN';
              this.ethColor = '#bb0000';
            }
            this.signal = this.transformService.translateSignal(
              value.signal.OpticalDdmRxPower
            );
            this.catvsignal = this.transformService.translateSignal(
              value.signal.OpticalDdmAniCATVRxPower
            );
            this.displayedCATV = this.catvsignal.toString();
            this.displayedSignal = this.signal.toString();
            //determine color assigned to signal level
           
            if (this.signal >= 0) this.signalColor = 'grey';
            else if (this.signal < -5 && this.signal >=-24) this.signalColor = '#00bb00';
            else if (this.signal <-24 && this.signal >= -27) this.signalColor = '#bbbb00';
            else this.signalColor = '#bb0000';


            if (!this.ontService.isPopup) {
             
              let date = new Date();
              let hours = date.getHours().toString();
              let minutes = date.getMinutes().toString();
              let seconds = date.getSeconds().toString();
              if (date.getHours() < 10) {
                hours = '0' + hours;
              }
              if (date.getMinutes() < 10) {
                minutes = '0' + minutes;
              }
              if (date.getSeconds() < 10) {
                seconds = '0' + seconds;
              }
              let dateStr = hours + ':' + minutes + ':' + seconds;
              if (this.signal != 0) {
                if (input.length > 15) input.shift();
                input.push({
                  date: dateStr,
                  value: this.signal,
                  color: this.signalColor,
                });
              }

              this.chartService.realTimeChart.data = input;
            }
          });
      }
      else
      {
        clearInterval(id);
      }
    }, updateInterval * 1000);
    this.id = id;
  }

  tabChanged(event) {
    if (event['tab']['textLabel'] != 'RealTime') {
      this.chartService
        .loadChartData(this.ont.ontSn, event['tab']['textLabel'])
        .subscribe((value) => {
          this.interval = event['tab']['textLabel'];
          setTimeout(() => {
            this.historicChart.disposeChart();
            this.historicChart.geneateChart(value, event['tab']['textLabel']);
          }, 500);
        });
    }
  }
}
