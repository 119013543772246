<div class="grid-container">
  <h2 *ngIf='oltList$.length' class="mat-h2">{{ oltList$[oltid$].name }}</h2>
  <h3 *ngIf='oltList$.length' class="mat-h3">{{ oltList$[oltid$].ipaddress }}</h3>
  <mat-grid-list cols="2" rowHeight="350px">
    <mat-grid-tile *ngFor="let card of cards | async; let i=index;" [colspan]="card.cols" [rowspan]="card.rows">
      <mat-card class="dashboard-card">
        <mat-card-header>
          <mat-card-title>
            {{card.title}}
            <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button mat-menu-item>Expand</button>
              <button mat-menu-item>Remove</button>
            </mat-menu>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">
          <ip6m-oltslotlist *ngIf="i == 0"></ip6m-oltslotlist>
          <ip6m-oltinfo *ngIf="i == 1"></ip6m-oltinfo>
          <ip6m-oltdetail *ngIf="i == 2"></ip6m-oltdetail>
          <ip6m-frameinfo *ngIf="i == 3"></ip6m-frameinfo>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
