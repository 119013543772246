import { Injectable } from '@angular/core';
import { ChartInterval, Charts, CustomerData, OntOpticalDdmInfo, RRDdata } from '@ip6m/types';
import { HttpClient } from '@angular/common/http';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { CustomerService } from '../customer/customer.service';
import { MarkerService } from '../marker/marker.service';
import { DdmService } from '../ddm/ddm.service';
import { TransformsService } from '../transforms/transforms.service';

interface DDM{
  signal:number;
  sn:string;
}
@Injectable({
  providedIn: 'root',
})

export class ChartService {
  public ddms:DDM[]=[];
  public ontsLoaded:boolean=false;
  public realTimeChart: am4charts.XYChart
  constructor(
    private http: HttpClient,
    private transformService:TransformsService,
    private ddmService:DdmService) {}
    
  loadChartData(ontSn: string, interval: ChartInterval) {
    return this.http.get<RRDdata[]>(
      '/api/v1/datacollector/' + ontSn + '/chartdata/' + interval
    );
  }

  public generateRealtimeChart() {
    this.realTimeChart = am4core.create('realtimeSignalChart', am4charts.XYChart);
    this.realTimeChart.paddingRight = 20;
    var categoryAxis =  this.realTimeChart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.minGridDistance = 50;
    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.startLocation = 0.5;
    categoryAxis.endLocation = 0.5;
    var valueAxis = this.realTimeChart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.fill = am4core.color('white');
    categoryAxis.renderer.labels.template.fill = am4core.color('white');
    valueAxis.renderer.labels.template.stroke = am4core.color('white');
    categoryAxis.renderer.labels.template.stroke = am4core.color('white');
    valueAxis.min = -40;
    valueAxis.max = 0;
    var series = this.realTimeChart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.categoryX = 'date';
    series.strokeWidth = 5;
    series.tensionX = 0.77;
    var bullet = series.bullets.push(new am4charts.Bullet());
    bullet.tooltipText = '{valueY}';

   

    bullet.adapter.add('fill', function (fill, target) {
      if ((<am4charts.LineSeriesDataItem>target.dataItem).valueY <-5 &&
          (<am4charts.LineSeriesDataItem>target.dataItem).valueY >=-24) {
        return am4core.color('#00FF00');
      }
      else if ((<am4charts.LineSeriesDataItem>target.dataItem).valueY < -24 &&
          (<am4charts.LineSeriesDataItem>target.dataItem).valueY >= -27) {
        return am4core.color('#FFFF00');
      }
      else  {
        return am4core.color('#FF00');
      }

     
    });

     /*if (this.signal >= 0) this.signalColor  = 'grey';
            else if (this.signal < -5 && this.signal >=-24) this.signalColor  = '#00ff00';
            else if (this.signal <-24 && this.signal >= -27) this.signalColor  = '#ffff00';
            else this.signalColor  = '#ff0000'; */
    var range = valueAxis.createSeriesRange(series);
    range.value = -5;
    range.endValue = -24;
    range.contents.stroke = am4core.color('#00FF00');
    var range = valueAxis.createSeriesRange(series);
    range.value = -24;
    range.endValue = -27;
    range.contents.stroke = am4core.color('#FFFF00');
    var range = valueAxis.createSeriesRange(series);
    range.value = -27;
    range.endValue = -50;
    range.contents.stroke = am4core.color('#FF0000');
    this.realTimeChart.cursor = new am4charts.XYCursor();
   
  }

  public intervalToChartLabel(data, interval: ChartInterval): string {
    //Used for getRrdData for readable labels
    var currentTime: Date = new Date(data);
    switch (interval) {
      case ChartInterval.Hourly:
        return (
          currentTime.getHours() +
          ':' +
          currentTime.getMinutes().toLocaleString()
        );
      case ChartInterval.Daily:
        return (
          currentTime.toLocaleString('en-us', { weekday: 'short' })
          + " "+ currentTime.getHours() +
          ':' +
          currentTime.getMinutes().toLocaleString()
        );
      case ChartInterval.Weekly:
        return (
          currentTime.toLocaleString('en-us', { month: 'short' }) +
          ' ' +
          currentTime.getDate().toLocaleString() +" "+ currentTime.getHours() +
          ':' +
          currentTime.getMinutes().toLocaleString()
        );
      case ChartInterval.Monthly:
        return (
          currentTime.toLocaleString('en-us', { month: 'short' }) +
          ' ' +
          currentTime.getDate() + " " + currentTime.getHours().toLocaleString() +
          ':00'
        );
      case ChartInterval.Annual:
        return (
          currentTime.toLocaleString('en-us', { month: 'short' }) +
          ' ' +
          currentTime.getDate().toLocaleString()
        );
    }
  }

  public generateHistoricChart(data): am4charts.XYChart {
    let chart = am4core.create('historicSignalChart', am4charts.XYChart);
    chart.data = data;
   
    chart.paddingRight = 20;
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'time';
    categoryAxis.renderer.minGridDistance = 65;
    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.startLocation = 0.5;
    categoryAxis.endLocation = 0.5;
    categoryAxis.renderer.labels.template.fontSize = 10;
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.fill = am4core.color('white');
    categoryAxis.renderer.labels.template.fill = am4core.color('white');
    valueAxis.renderer.labels.template.stroke = am4core.color('white');
    categoryAxis.renderer.labels.template.stroke = am4core.color('white');
    valueAxis.min = -40;
    valueAxis.max = 0;
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'signal';
    series.dataFields.categoryX = 'time';
    series.strokeWidth = 5;
    series.tensionX = 0.77;
    series.connect = false;
    var bullet = series.bullets.push(new am4charts.Bullet());
    bullet.tooltipText = '{valueY}';
    bullet.adapter.add('fill', function (fill, target) {
      if ((<am4charts.LineSeriesDataItem>target.dataItem).valueY <-5 &&
      (<am4charts.LineSeriesDataItem>target.dataItem).valueY >=-24) {
        return am4core.color('#00FF00');
      }
      else if ((<am4charts.LineSeriesDataItem>target.dataItem).valueY < -24 &&
          (<am4charts.LineSeriesDataItem>target.dataItem).valueY >= -27) {
        return am4core.color('#FFFF00');
      }
      else  {
        return am4core.color('#FF00');
      }
    });
    var range = valueAxis.createSeriesRange(series);
    range.value = -5;
    range.endValue = -24;
    range.contents.stroke = am4core.color('#00FF00');
    var range = valueAxis.createSeriesRange(series);
    range.value = -24;
    range.endValue = -27;
    range.contents.stroke = am4core.color('#FFFF00');
    var range = valueAxis.createSeriesRange(series);
    range.value = -27;
    range.endValue = -50;
    range.contents.stroke = am4core.color('#FF0000');
    chart.cursor = new am4charts.XYCursor();
    return chart;
  }

  generateOntChart() {
      let ddMs:DDM[]=[]
      let critical=0;
      let online=0;
      let warning=0;
      let offline=0;
      this.ddmService.getAllDDMInfos().subscribe((ddms:OntOpticalDdmInfo[])=>{
         
           
          
            Object.entries(ddms).forEach(([key, value]) => {
              
              let signal=this.transformService.translateSignal(value.OpticalDdmRxPower)

              ddMs.push({
                signal:signal,
                sn:key
              })
              if(signal<0)
              {
              if (signal <= -27) {
                critical++;
              }
              if (signal<=-25 && signal >-27) {
                warning++;
              }
              if (signal < -10 && signal >-25) {
                online++;
              }
            }
            else
            {
              offline++;
            }
             

                  
                  
                
            });
            this.ddms=ddMs;
            this.ontsLoaded=true;
          

           
             
    let chart = am4core.create('chartdiv', am4charts.XYChart);
    chart.data = [
      {
        status: 'Critical('+critical+")",
        number:  critical,
        color: '#a83232',
      },
      {
        status: 'Online('+online+")",
        number: 
          online,
        color: '#32a852',
      },

      {
        status: 'Warning('+warning+")",
        number: warning,
        color: '#a8a232',
      },
      {
        status: 'Offline('+offline+")",
        number: offline,
        color: 'rgb(55, 55, 55)',
      },
    ];

    //Order types by their customer num
    for (let i = 0; i < chart.data.length; i++) {
      for (let j = 0; j < chart.data.length; j++) {
        if (chart.data[i].number < chart.data[j].number) {
          let tmp = chart.data[i];
          chart.data[i] = chart.data[j];
          chart.data[j] = tmp;
        }
      }
    }

   




    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "status";
    categoryAxis.renderer.grid.template.location = 0;
    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueX = "number";
    series.dataFields.categoryY = "status";
    series.columns.template.adapter.add('fill', (fill, target) => {
      return am4core.color(chart.data[target.dataItem.index].color);
    });
    series.columns.template.adapter.add('stroke', (stroke, target) => {
      return am4core.color(chart.data[target.dataItem.index].color);
    });
    valueAxis.renderer.labels.template.fill = am4core.color('white');
    categoryAxis.renderer.labels.template.dx=100
    categoryAxis.renderer.labels.template.fill = am4core.color('white');
    chart.paddingLeft=-80
   
         
      
      })
      
   
   
  }
}
