import { Component } from '@angular/core';



@Component({
  selector: 'ip6m-ontdialog',
  templateUrl: './ontdialog.component.html',
  styleUrls: ['./ontdialog.component.css'],
})
export class OntdialogComponent {
  
  constructor(
    
  ) {
   
  }


 

  
}